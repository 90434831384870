import { Button, Popover, PopoverSurface, PopoverTrigger, Tooltip } from '@fluentui/react-components';
import React, { FC } from 'react';
import { ICustomPopoverProps } from './interfaces';
import { ChevronDownFilled } from "@fluentui/react-icons";
import { DivWrapperTextSC } from './styles';
import getFormatedText from '../../tools/getFormatedText';
import { TooltipContent } from '../Chapters/Tasks/CreateTasks/components/TooltipContent';

export const CustomPopover: FC<ICustomPopoverProps> = (props) => {

    const {
        open,
        onOpenChange,
        label = '',
        children,
        isCount = false,
        labelsList = []
    } = props



    return <Popover
        positioning={'above'}
        open={open}
        onOpenChange={onOpenChange}
    >
        <PopoverTrigger disableButtonEnhancement>
            <Tooltip content={<TooltipContent
                textList={labelsList.length > 0 ? labelsList : [label]}
            />} relationship="label" {...props}>
                <Button
                    icon={<ChevronDownFilled />}
                    iconPosition="after"
                >
                    <DivWrapperTextSC>
                        {
                            isCount ? labelsList.length > 1 ? `...${labelsList.length}` : getFormatedText(label, 32) :
                                getFormatedText(label, 32)
                        }
                    </DivWrapperTextSC>

                </Button>
            </Tooltip>

        </PopoverTrigger>

        <PopoverSurface tabIndex={-1}>
            {
                children
            }

        </PopoverSurface>
    </Popover>;
};