import { BodyCreateTasksRowSC } from '../../styles';
import React, { useEffect, useState } from 'react';
import { ColumnOptions, FocusTable, TableRange } from '../../../../FocusTable';
import { IConnectorItemListForTable, TListSettings } from '../../interfaces';
import { useTaskStore } from '../../../../../store/useTaskStore';
import { columnsOptions } from '../settings/columnForTable';
import { useGeneralStore } from '../../../../../store';
import 'react-datasheet/lib/react-datasheet.css';
import { KFACTORHEIGHTTABLE } from '../../../../../constants';
import { compare } from '../../../../../tools/compare';
import getIsIntersection from '../../../../../tools/getIsIntersection';
import { DateTime } from 'luxon';
import filteringTableList from '../../../../../tools/filteringTableList';
import { filteringByConnectorTypes } from '../../tools/filteringConnectorTypes';

export const BodyCreateTasks = () => {
    const [connectorsListForTable, setConnectorsListForTable] = useState<IConnectorItemListForTable[]>([]);
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [tableDataListTemp, setTableDataListTemp] = useState<IConnectorItemListForTable[]>([]);
    const [oldListDataTable, setOldListDataTable] = useState<IConnectorItemListForTable[]>([]);
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const { getGeneralStore } = useGeneralStore();

    const {
        tenants,
        connectors,
        tenantDataByTenantId,
        tableDataList,
        tenantsFilterValue,
        selectedTypesConnector,
        selectedConnector,
        statusSelectedAll,
        selectedListRowTable,
        selectedDateFrom,
        selectedDateTo,
        connectorsListIntersectionDate,
        listSettingsTable,
    } = getTasksStore();
    const { pLDataByPLId } = getGeneralStore();

    useEffect(() => {
        if (
            tenantDataByTenantId &&
            pLDataByPLId &&
            connectors.length > 0 &&
            Object.keys(tenantDataByTenantId).length > 0 &&
            Object.keys(pLDataByPLId).length > 0
        ) {
            const list: IConnectorItemListForTable[] = [];
            connectors.forEach((item) => {
                if (item.id) {
                    list.push({
                        id: item.id,
                        tenantName: tenantDataByTenantId[item.tenant_id]?.name,
                        tenantId: item.tenant_id,
                        locationId: tenantDataByTenantId[item.tenant_id]?.project_location,
                        locationName: pLDataByPLId[tenantDataByTenantId[item.tenant_id]?.project_location]?.name,
                        connectorType: item.connector_type,
                        connectorId: item.id,
                        dateForm: item.date_from,
                        dateTo: item.date_to,
                    });
                }
            });
            updateTasksStore({
                tableDataList: list,
            });
        }
    }, [tenantDataByTenantId, connectors, pLDataByPLId]);

    useEffect(() => {
        let listTableTemp: IConnectorItemListForTable[] = oldListDataTable;

        listSettingsTable.forEach((item) => {
            if (item.id === 'active' && item.checked) {
                listTableTemp = tableDataListTemp.filter((item2, i) =>
                    connectorsListIntersectionDate.includes(item2.connectorId),
                );
            }
        });
        // console.log('refgewerqf', tableDataListTemp, listTableTemp);
        setTableDataListTemp(listTableTemp);
    }, [listSettingsTable, connectorsListIntersectionDate]);

    useEffect(() => {
        window.onresize = onSizeWindow;
        onResizeAfterEffect();
        let date = new Date();
        // date.setDate(date.getDate() - 31);
        // updateTasksStore({
        //     selectedDateFrom: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
        //     selectedDateTo: DateTime.now().toFormat('yyyy-MM-dd'),
        // });
    }, []);

    useEffect(() => {
        setTableDataListTemp(
            filteringTableList<IConnectorItemListForTable>({
                keys: ['tenantId', 'tenantName'],
                list: tableDataList,
                valueFilter: tenantsFilterValue,
            }),
        );

        setOldListDataTable(
            filteringTableList<IConnectorItemListForTable>({
                keys: ['tenantId', 'tenantName'],
                list: tableDataList,
                valueFilter: tenantsFilterValue,
            }),
        );
    }, [tenantsFilterValue, tableDataList]);

    useEffect(() => {
        let temp: IConnectorItemListForTable[] = filteringByConnectorTypes<IConnectorItemListForTable>({
            keys: ['connectorType'],
            list: selectedTypesConnector,
            filterList: oldListDataTable,
        });

        if (selectedTypesConnector.length === 0) {
            temp = oldListDataTable;
        }
        // setOldListDataTable(temp);
        setTableDataListTemp(temp);
    }, [selectedTypesConnector, oldListDataTable]);

    useEffect(() => {
        let selectedListRowTableTemp: number[] = [];
        tableDataListTemp.forEach((item, i) => {
            selectedConnector.forEach((item2) => {
                if (item.id === item2.id) {
                    selectedListRowTableTemp.push(i);
                }
            });
        });

        updateTasksStore({
            statusSelectedAll:
                tableDataListTemp.length > 0
                    ? compare(selectedConnector, filterIntersection(tableDataListTemp))
                    : false,
            selectedListRowTable: selectedListRowTableTemp,
        });
    }, [selectedConnector, tableDataListTemp]);

    useEffect(() => {
        let tempArray: number[] = [];
        let arraySelectedConnectorTemp: IConnectorItemListForTable[] = [];
        let arraySelectedConnectorRowTemp: number[] = [];
        tableDataListTemp.forEach((item, i) => {
            if (
                getIsIntersection({
                    dateToMain: selectedDateTo,
                    dateFromMain: selectedDateFrom,
                    dateToCompare: item.dateTo,
                    dateFromCompare: item.dateForm,
                })
            ) {
                tempArray.push(item.connectorId);
                if (selectedListRowTable.includes(i)) {
                    arraySelectedConnectorRowTemp.push(i);
                    arraySelectedConnectorTemp.push(item);
                }
            }
        });

        updateTasksStore({
            connectorsListIntersectionDate: tempArray,
            selectedConnector: arraySelectedConnectorTemp,
            selectedListRowTable: arraySelectedConnectorRowTemp,
        });
    }, [selectedDateFrom, selectedDateTo, tableDataListTemp]);

    const onSizeWindow = (e: any) => {
        setTableHeight(e.target.innerHeight - KFACTORHEIGHTTABLE);
    };

    const onResizeAfterEffect = () => {
        setTableHeight(window.innerHeight - KFACTORHEIGHTTABLE);
    };

    const onSelectionChange = (selection: TableRange | null, selectedRows: any[]) => {};

    const selectAllRow = (status: boolean) => {
        const filteredArray = filterIntersection(tableDataListTemp);
        updateTasksStore({
            selectedConnector: status ? filteredArray : [],
            statusSelectedAll: status,
        });
    };

    const filterIntersection = (list: IConnectorItemListForTable[]) => {
        return list.filter((item) => {
            return getIsIntersection({
                dateToMain: selectedDateTo,
                dateFromMain: selectedDateFrom,
                dateToCompare: item.dateTo,
                dateFromCompare: item.dateForm,
            });
        });
    };

    const selectRow = (row: number) => {
        let selectedListConnectorTemp = [...selectedConnector];
        let index = 0;
        const found = selectedConnector.find((item, i) => {
            index = i;
            return item.id === tableDataListTemp[row].id;
        });
        const found2 = tableDataListTemp.find((item, i) => {
            return item.id === tableDataListTemp[row].id;
        });
        if (found) {
            selectedListConnectorTemp.splice(index, 1);
        } else {
            if (found2) {
                selectedListConnectorTemp = [...selectedConnector, found2];
            }
        }
        updateTasksStore({
            selectedConnector: [...selectedListConnectorTemp],
        });
    };

    return (
        <BodyCreateTasksRowSC>
            <FocusTable
                columnsOptions={columnsOptions}
                maxHeight={tableHeight}
                // @ts-ignore
                onSelectionChange={onSelectionChange}
                // @ts-ignore
                value={tableDataListTemp}
                selectAllRow={selectAllRow}
                selectRow={selectRow}
                isSelectedAll={statusSelectedAll}
                selectedListRow={selectedListRowTable}
                isDisabledRows={true}
                listEnableRow={connectorsListIntersectionDate}
                isShowCheckBox={true}
            />
        </BodyCreateTasksRowSC>
    );
};
