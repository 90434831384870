import { Label, PopoverProps } from '@fluentui/react-components';
import { IParamsOnChangeSelection, TListSettings, TOption } from '../interfaces';
import { DivWrapperInputAndLabelSC } from '../styles';
import { Select } from '../../../Select/Select';
import React from 'react';

export const TableSettingsSelect = (props: {
    labelG: string;
    label: string;
    open: boolean;
    onOpenChange?: PopoverProps['onOpenChange'];
    onChangeSelection: (params: IParamsOnChangeSelection) => void;
    listOptions: TListSettings;
    selectedIds: string[];
    keyName: string;
}) => {
    const { labelG, keyName, label, open, onOpenChange, onChangeSelection, listOptions, selectedIds } = props;

    const onSelection = (option: TOption) => {
        const updatedSettings = listOptions.map((item) =>
            item.id === option.id ? { ...item, checked: !item.checked } : item,
        );

        onChangeSelection({
            [keyName]: updatedSettings,
        });
    };

    const cretaeNewListEditChecked = (list: TListSettings, status: 'all' | 'zero') => {
        return list.map((item) => {
            return {
                ...item,
                checked: status === 'all',
            };
        });
    };

    const selectAll = () => {
        onChangeSelection({
            [keyName]: [...cretaeNewListEditChecked(listOptions, 'all')],
        });
    };

    const clearAll = () => {
        onChangeSelection({
            [keyName]: [...cretaeNewListEditChecked(listOptions, 'zero')],
        });
    };
    return (
        <DivWrapperInputAndLabelSC>
            <Label>{labelG}</Label>
            <Select
                selectAll={selectAll}
                clearAll={clearAll}
                label={label}
                open={open}
                onOpenChange={onOpenChange}
                onChangeSelection={onSelection}
                listOptions={listOptions}
                isMulti={true}
                selectedIds={selectedIds}
                isShowButtons={true}
            />
        </DivWrapperInputAndLabelSC>
    );
};
