interface Args<T> {
    keys: string[];
    list: T[];
    valueFilter: string;
    // typeCompare?: 'strict' | 'low';
}
export const filteringTableDataMoreZero = <T>(params: Args<T>): T[] => {
    const { keys, list, valueFilter } = params;

    if (keys.length === 2) {
        return list.filter((item) => Number(item[keys[0] as keyof T]) > 0 || Number(item[keys[1] as keyof T]) > 0);
    } else if (keys.length === 1) {
        return list.filter((item) => {
            return Number(item[keys[0] as keyof T]) > 0;
        });
    }

    return list;
};
