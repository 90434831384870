import { IConnector, ITableListTasksItem } from '../../../../types';
import { IItemForTable } from '../../../Table/interfaces';

const getItemsForTable = (list: ITableListTasksItem[]): IItemForTable[] => {
    return list.map((item) => {
        return {
            id: {
                label: item.connectorId ? item.connectorId : '',
            },
            connector_type: {
                label: item.connectorType ? item.connectorType : '',
            },
            date: {
                label: item.dateFrom ? item.dateFrom : '',
            },
            tenantId: {
                label: item.tenantId ? item.tenantId : '',
            },
            status: {
                label: item.status ? item.status : '',
            },
        };
    });
};

export default getItemsForTable;
