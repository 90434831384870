export const roundMillisecond = (time: string): string => {
    const seconds: number = Number(time.substring(6, time.length - 1));
    const secondsAfterRound: number = round(seconds, 100);

    return formatTime(time, String(secondsAfterRound));
};

export const round = (num: number, kFactor: number): number => {
    return Math.round(num * kFactor) / kFactor;
};

const formatTime = (time: string, second: string): string => {
    const strZero = second.substring(second.length - 2, second.length - 1) === '.' ? '0' : '';

    return time.substring(6, 7) === '0'
        ? time.substring(0, 6) + 0 + second.substring(0, second.length - 1) + strZero
        : time.substring(0, 6) + second.substring(0, second.length - 1) + strZero;
};
