import { IPropsUseTableColumn } from '../components/Chapters/Tasks/interfaces';
import { useEffect, useState } from 'react';
import { ColumnOptions } from '../components/FocusTable';

const enumIds: { [key: string]: string } = {
    PLId: 'showPLId',
    taskSource: 'showTaskSource',
    isIsolated: 'showIsolatedWorkers',
    priority: 'showPriority',
    attempt: 'showAttempt',
    startedAt: 'showStartedAt',
    delayedTo: 'showDelayedTo',
    duration: 'showDuration',
};

export const useTableColumn = (params: IPropsUseTableColumn) => {
    const { listSettings, columnsOptions } = params;

    const [tableColumns, setTableColumns] = useState<ColumnOptions[]>([]);

    useEffect(() => {
        if (listSettings.length > 0 && columnsOptions.length > 0) {
            let array: ColumnOptions[] = [];
            columnsOptions.forEach((item) => {
                if (enumIds[item.id]) {
                    const foundSetting = listSettings.find((item2) => {
                        return item2.id === enumIds[item.id];
                    });

                    array.push({
                        ...item,
                        visible: foundSetting ? foundSetting.checked : false,
                    });
                } else {
                    array.push({
                        ...item,
                    });
                }
            });

            setTableColumns(array);
        }
    }, [listSettings, columnsOptions]);
    return tableColumns;
};
