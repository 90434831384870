import Modals from '../../../../Modal/Modals';
import { DivWrapperButtonsSC, DivWrapperModalButtonsSC, DivWrapperModalContentSC } from '../../styles';
import { CustomTable } from '../../../../Table/CustomTable';
import { columnsSelectedTasks } from '../settings/tableModalColumns';
import { RenderRowConnectors } from '../../../../Connectors/components/RenderRowConnectors';
import { Button } from '@fluentui/react-components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TableColumnId,
    useFluent,
    useScrollbarWidth,
    useTableFeatures,
    useTableSelection,
    useTableSort,
} from '@fluentui/react-components';
import { IItemForTable } from '../../../../Table/interfaces';
import getItemsForTable from '../../tools/getItemForTableSelectedTasks';
import { IPropsControlButtons } from '../../interfaces';

export const ControlButtons: FC<IPropsControlButtons> = (props) => {
    const { disabledButton, onCancel, onRestart, selectedTasks, isPendingCreate, isPendingCancel } = props;

    const [modalOptionsCancel, setModalOptionsCancel] = useState<{
        show: boolean;
    }>({
        show: false,
    });
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [heightContent, setHeightContent] = useState<number>(200);
    const [modalOptionsRestart, setModalOptionsRestart] = useState<{
        show: boolean;
    }>({
        show: false,
    });
    const { t } = useTranslation();
    const itemsConnectorTable: IItemForTable[] = getItemsForTable(selectedTasks);
    const {
        getRows,
        selection: { toggleRow },
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns: columnsSelectedTasks,
            items: itemsConnectorTable,
        },
        [
            useTableSelection({
                selectionMode: 'single',
                defaultSelectedItems: new Set([0, 1]),
            }),
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );

    useEffect(() => {
        if (!isPendingCreate) {
            setModalOptionsRestart({ show: isPendingCreate });
        }
        if (!isPendingCancel) {
            setModalOptionsCancel({ show: isPendingCancel });
        }
    }, [isPendingCancel, isPendingCreate]);

    const onClickModalCancel = (status: boolean) => () => {
        setModalOptionsCancel((prevState) => {
            return {
                ...prevState,
                show: status,
            };
        });
    };

    const onClickModalRestart = (status: boolean) => () => {
        setModalOptionsRestart((prevState) => {
            return {
                ...prevState,
                show: status,
            };
        });
    };

    const onClickCancel = () => {
        onCancel();
    };

    const onClickRestart = () => {
        onRestart();
    };

    const rows = sort(
        getRows((row) => {
            const selected = false;
            return {
                ...row,
                onClick: (e: React.MouseEvent) => {
                    // onClickItem(String(row.item.id.label));
                    toggleRow(e, row.rowId);
                },
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === ' ') {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ('brand' as const) : ('none' as const),
            };
        }),
    );

    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });
    const createStylesPres = () => {
        return {
            width: heightContent < rows.length * 45 ? scrollbarWidth : 0,
        };
    };
    return (
        <DivWrapperButtonsSC>
            <Modals
                title={t('Cancel selected')}
                modalOptions={modalOptionsCancel}
                closeModal={onClickModalCancel(false)}
            >
                <DivWrapperModalContentSC>
                    <span>{`${t('Are you sure you want to stop the tasks?')} ${selectedTasks.length} tasks`}</span>
                    <CustomTable
                        columns={columnsSelectedTasks}
                        rows={rows}
                        headerSortProps={headerSortProps}
                        style={createStylesPres()}
                        height={heightContent}
                        iItemProjectsForTables={itemsConnectorTable}
                        RenderRowInner={RenderRowConnectors}
                    />
                    <DivWrapperModalButtonsSC>

                        <Button appearance={'primary'} onClick={onClickCancel} disabled={disabledButton}>
                            {t('Ok')}
                        </Button>
                    </DivWrapperModalButtonsSC>
                </DivWrapperModalContentSC>
            </Modals>
            <Modals
                title={t('Restart selected')}
                modalOptions={modalOptionsRestart}
                closeModal={onClickModalRestart(false)}
            >
                <DivWrapperModalContentSC>
                    <span>{`${t('Are you sure you want to restart the tasks?')} ${selectedTasks.length} tasks`}</span>
                    <CustomTable
                        columns={columnsSelectedTasks}
                        rows={rows}
                        headerSortProps={headerSortProps}
                        style={createStylesPres()}
                        height={heightContent}
                        iItemProjectsForTables={itemsConnectorTable}
                        RenderRowInner={RenderRowConnectors}
                    />
                    <DivWrapperModalButtonsSC>

                        <Button appearance={'primary'} onClick={onClickRestart} disabled={disabledButton}>
                            {t('Ok')}
                        </Button>
                    </DivWrapperModalButtonsSC>
                </DivWrapperModalContentSC>
            </Modals>
            <Button appearance="primary" onClick={onClickModalCancel(true)} disabled={disabledButton}>
                {t('Cancel selected')}
            </Button>
            <Button appearance="primary" onClick={onClickModalRestart(true)} disabled={disabledButton}>
                {t('Restart selected')}
            </Button>
        </DivWrapperButtonsSC>
    );
};

