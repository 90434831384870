import { IParamsFilteringConnectorTypes } from '../interfaces';
import filteringTableList from '../../../../tools/filteringTableList';

export const filteringByConnectorTypes = <T>(params: IParamsFilteringConnectorTypes<T>): T[] => {
    const { keys, list, filterList, typeCompare = 'low' } = params;

    let temp: T[] = [];

    list.forEach((item) => {
        temp = [
            ...temp,
            ...filteringTableList<T>({
                keys: keys,
                list: filterList,
                valueFilter: item.connector_type,
                typeCompare,
            }),
        ];
    });
    return temp;
};


