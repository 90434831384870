import React, { useEffect, useState } from 'react';
import { IBodyTaskCreator, IConnectorItemListForTable, IParamsCancelTask, IStatuses } from '../../interfaces';
import { useTaskStore } from '../../../../../store/useTaskStore';
import { useGeneralStore } from '../../../../../store';
import { DateTime } from 'luxon';
import getIsIntersection from '../../../../../tools/getIsIntersection';
import { KFACTORHEIGHTTABLE_MONITORING } from '../../../../../constants';
import { FocusTable, TableRange } from '../../../../FocusTable';
import {
    BodyMonitoringTasksRowSC,
    DivWrapperButtonsSC,
    DivWrapperChartAndTitleSC,
    DivWrapperChartsContentSC,
    DivWrapperModalContentSC,
} from '../../styles';
import { columnsOptions } from '../settings/tableColumns';
import { useTableColumn } from '../../../../../hooks/useTableColumn';
import { filteringByConnectorTypes } from '../../tools/filteringConnectorTypes';
import { ITableListTasksItem } from '../../../../../types';
import filteringListByTaskStatuses from '../../tools/filteringListByTaskStatuses';
import { usePostTaskCanceler } from '../../../../../api/usePostTaskCanceler';
import { usePostTaskCreate } from '../../../../../api/usePostTaskCreate';
import { ChartBox } from './ChartBox';
import { getPercentage } from '../../tools/getPercentage';
import { getChunkArrays } from '../../tools/getChunkArrays';
import { CHUNK_SIZE } from '../../constants';
import { createBody } from '../../tools/createBody';
import { Button, Title3 } from '@fluentui/react-components';
import Modals from '../../../../Modal/Modals';
import { CustomTable } from '../../../../Table/CustomTable';
import { columnsSelectedTasks } from '../settings/tableModalColumns';
import { RenderRowConnectors } from '../../../../Connectors/components/RenderRowConnectors';
import { useTranslation } from 'react-i18next';
import { ControlButtons } from './ControlButtons';
import { arrayCancelStatuses } from '../settings/arrayCancelStatuses';

export const BodyMonitoring = () => {
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [tableDataListTemp, setTableDataListTemp] = useState<ITableListTasksItem[]>([]);
    const [oldListDataTable, setOldListDataTable] = useState<ITableListTasksItem[]>([]);
    const [selectedCells, setSelectedCells] = useState<TableRange | null>(null);
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const { getGeneralStore } = useGeneralStore();
    const { t } = useTranslation();
    const [statuses, setStatuses] = useState<IStatuses>({
        error: {
            number: 0,
            name: 'Error',
            count: 0,
        },
        pending: {
            number: 0,
            name: 'Pending',
            count: 0,
        },
        success: {
            number: 0,
            name: 'Success',
            count: 0,
        },
        noReceipts: {
            number: 0,
            name: 'No receipts',
            count: 0,
        },
    });
    const {
        connectors,
        tenantDataByTenantId,
        tableDataList,
        selectedTypesConnector,
        statusSelectedAll,
        selectedListRowTable,
        selectedDateFrom,
        selectedDateTo,
        selectedCreatedFrom,
        selectedCreatedTo,
        listSettingsTableMonitoring,
        tableListTasks,
        listTaskStatusesFilter,
        selectedTasks,
        selectedDateMonitoringTo,
        selectedMonitoringDateFrom,
        taskDataByTaskId,
    } = getTasksStore();
    const { pLDataByPLId } = getGeneralStore();

    const { mutate: mutateCanceler, isPending: isPendingCanceler } = usePostTaskCanceler('statusReloadAndCanceler');
    const { mutate: mutateReload, isPending: isPendingCreater } = usePostTaskCreate('statusReloadAndCanceler');

    const tableColumns = useTableColumn({
        listSettings: listSettingsTableMonitoring,
        columnsOptions,
    });
    useEffect(() => {
        if (
            tenantDataByTenantId &&
            pLDataByPLId &&
            connectors.length > 0 &&
            Object.keys(tenantDataByTenantId).length > 0 &&
            Object.keys(pLDataByPLId).length > 0
        ) {
            const list: IConnectorItemListForTable[] = [];
            connectors.forEach((item) => {
                if (item.id) {
                    list.push({
                        id: item.id,
                        tenantName: tenantDataByTenantId[item.tenant_id]?.name,
                        tenantId: item.tenant_id,
                        locationId: tenantDataByTenantId[item.tenant_id]?.project_location,
                        locationName: pLDataByPLId[tenantDataByTenantId[item.tenant_id]?.project_location]?.name,
                        connectorType: item.connector_type,
                        connectorId: item.id,
                        dateForm: item.date_from,
                        dateTo: item.date_to,
                    });
                }
            });
            updateTasksStore({
                tableDataList: list,
            });
        }
    }, [tenantDataByTenantId, connectors, pLDataByPLId]);

    useEffect(() => {
        window.onresize = onSizeWindow;
        onResizeAfterEffect();
        let date = new Date();
        date.setDate(date.getDate() - 31);
        updateTasksStore({
            selectedDateFrom: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
            selectedDateTo: DateTime.now().toFormat('yyyy-MM-dd'),
        });
    }, []);

    useEffect(() => {
        let temp: ITableListTasksItem[] = filteringByConnectorTypes<ITableListTasksItem>({
            keys: ['connectorType'],
            list: selectedTypesConnector,
            filterList: tableListTasks,
            typeCompare: 'strict',
        });

        if (selectedTypesConnector.length === 0) {
            temp = tableListTasks;
        }

        temp = filteringListByTaskStatuses<ITableListTasksItem>({
            list: temp,
            listStatuses: listTaskStatusesFilter,
        });
        updateTasksStore({
            selectedTasks: [],
        });
        setSelectedCells(null);
        setOldListDataTable(temp);
        setTableDataListTemp(temp);
    }, [selectedTypesConnector, tableListTasks, listTaskStatusesFilter]);

    useEffect(() => {
        if (tableDataListTemp.length > 0) {
            const filteredError = tableDataListTemp.filter((item) => (item.statusCode ? item.statusCode < -4 : false));
            const filteredPending = tableDataListTemp.filter((item) =>
                item.statusCode ? item.statusCode === -4 : false,
            );
            const filteredSuccess = tableDataListTemp.filter((item) =>
                item.statusCode ? item.statusCode === -2 || item.statusCode > 0 : false,
            );
            const filteredWaiting = tableDataListTemp.filter((item) =>
                item.statusCode !== undefined ? item.statusCode === 0 : false,
            );

            const error = getPercentage(filteredError.length, tableDataListTemp.length);
            const pending = getPercentage(filteredPending.length, tableDataListTemp.length);
            const success = getPercentage(filteredSuccess.length, tableDataListTemp.length);
            const waiting = getPercentage(filteredWaiting.length, tableDataListTemp.length);
            setStatuses({
                error: {
                    number: error,
                    name: 'Error',
                    count: filteredError.length,
                },
                pending: {
                    number: pending,
                    name: 'Pending',
                    count: filteredPending.length,
                },
                success: {
                    number: success,
                    name: 'Success',
                    count: filteredSuccess.length,
                },
                noReceipts: {
                    number: waiting,
                    name: 'No receipts',
                    count: filteredWaiting.length,
                },
            });
        }
    }, [tableDataListTemp]);

    useEffect(() => {
        let tempArray: number[] = [];
        let arraySelectedConnectorTemp: ITableListTasksItem[] = [];
        let arraySelectedConnectorRowTemp: number[] = [];
        tableDataListTemp.forEach((item, i) => {
            if (
                getIsIntersection({
                    dateToMain: selectedDateTo,
                    dateFromMain: selectedDateFrom,
                    dateToCompare: item.dateTo || '',
                    dateFromCompare: item.dateFrom || '',
                })
            ) {
                tempArray.push(item.id);
                if (selectedListRowTable.includes(i)) {
                    arraySelectedConnectorRowTemp.push(i);
                    arraySelectedConnectorTemp.push(item);
                }
            }
        });

        updateTasksStore({
            connectorsListIntersectionDate: tempArray,
        });
    }, [selectedDateFrom, selectedDateTo, tableDataListTemp]);

    const getHeaderHeight = (): number => {
        return document.getElementById('HeaderMonitoring')
            ? document.getElementById('HeaderMonitoring')!.getBoundingClientRect().height
            : 0;
    };

    const onSizeWindow = (e: any) => {
        setTableHeight(e.target.innerHeight - (KFACTORHEIGHTTABLE_MONITORING + getHeaderHeight()));
    };

    const onResizeAfterEffect = () => {
        setTableHeight(window.innerHeight - (KFACTORHEIGHTTABLE_MONITORING + getHeaderHeight()));
    };

    const onSelectionChange = (selection: TableRange | null, selectedRows: ITableListTasksItem[]) => {
        updateTasksStore({
            selectedTasks: selectedRows,
        });
        setSelectedCells(selection);
    };

    const filterIntersection = (list: IConnectorItemListForTable[]) => {
        return list.filter((item) => {
            return getIsIntersection({
                dateToMain: selectedDateTo,
                dateFromMain: selectedDateFrom,
                dateToCompare: item.dateTo,
                dateFromCompare: item.dateForm,
            });
        });
    };

    const onCancelTask = (id: number) => {
        if (taskDataByTaskId) {
            const obj: IParamsCancelTask = {
                date_from: taskDataByTaskId[id].dateFrom!,
                date_to: taskDataByTaskId[id].dateFrom!,
                project_location_ids: [taskDataByTaskId[id].PLId!],
                tenant_ids: [taskDataByTaskId[id].tenantId!],
                connector_ids: [taskDataByTaskId[id].connectorId!],
                connector_types: [taskDataByTaskId[id].connectorType!],
            };
            mutateCanceler(obj);
        }
    };

    const onReloadTask = (id: number) => {
        if (taskDataByTaskId) {
            const obj: IBodyTaskCreator = {
                date_from: taskDataByTaskId[id].dateFrom!,
                date_to: taskDataByTaskId[id].dateFrom!,
                project_location_ids: [taskDataByTaskId[id].PLId!],
                tenant_ids: [taskDataByTaskId[id].tenantId!],
                connector_ids: [taskDataByTaskId[id].connectorId!],
                connector_types: [taskDataByTaskId[id].connectorType!],
            };
            mutateReload(obj);
        }
    };

    const createDataReq = () => {
        const chunkArray = getChunkArrays(selectedTasks, CHUNK_SIZE);
        let arrayBodies: IParamsCancelTask[] = [];
        chunkArray.forEach((item, i) => {
            arrayBodies.push(createBody(item) as IParamsCancelTask);
        });
        return arrayBodies;
    };

    const onCancel = () => {
        const arrayBodies: IParamsCancelTask[] = createDataReq();
        arrayBodies.forEach((item) => {
            mutateCanceler(item);
        });
    };

    const onRestart = () => {
        const arrayBodies: IBodyTaskCreator[] = createDataReq() as IBodyTaskCreator[];
        arrayBodies.forEach((item) => {
            mutateReload(item);
        });
    };

    return (
        <BodyMonitoringTasksRowSC>
            <DivWrapperChartAndTitleSC>
                <Title3>{`${t('Total tasks')}: ${tableDataListTemp.length} (${
                    selectedMonitoringDateFrom
                        ? `${t('Date')}: ${selectedMonitoringDateFrom} - ${selectedDateMonitoringTo}`
                        : selectedCreatedFrom
                          ? `${t('Created')}: ${selectedCreatedFrom} - ${selectedCreatedTo}`
                          : ''
                })`}</Title3>
                <DivWrapperChartsContentSC>
                    <ChartBox statuses={statuses} />
                    <div></div>
                    <ControlButtons
                        disabledButton={selectedTasks.length === 0}
                        onCancel={onCancel}
                        onRestart={onRestart}
                        selectedTasks={selectedTasks}
                        isPendingCancel={isPendingCanceler}
                        isPendingCreate={isPendingCreater}
                    />
                </DivWrapperChartsContentSC>
            </DivWrapperChartAndTitleSC>

            <FocusTable
                columnsOptions={tableColumns}
                maxHeight={tableHeight}
                cancelTask={onCancelTask}
                selectedCells={selectedCells}
                // @ts-ignore
                onSelectionChange={onSelectionChange}
                // @ts-ignore
                value={tableDataListTemp}
                // selectAllRow={selectAllRow}
                // selectRow={selectRow}
                isSelectedAll={statusSelectedAll}
                selectedListRow={selectedListRowTable}
                // isDisabledRows={true}
                // listEnableRow={connectorsListIntersectionDate}
                isShowActions={true}
                reloadTask={onReloadTask}
                isPositionCell={true}
                arrayCancelStatuses={arrayCancelStatuses}
                taskDataByTaskId={taskDataByTaskId}
            />
        </BodyMonitoringTasksRowSC>
    );
};
