import { useEffect, useState } from 'react';

interface HandlerArgs<T> {
    [key: string]: T;
}

interface Args<T> {
    key: string;
    handler: (args: HandlerArgs<T>) => void;
    dataFromStore: T;
}

/**
 * Custom hook to synchronize a state with localStorage.
 *
 * @template T - The type of the data to be stored in localStorage.
 * @param {Args<T>} args - The arguments for the hook.
 * @param {string} args.key - The key under which the data is stored in localStorage.
 * @param {(data: { [key: string]: T }) => void} args.handler - The handler function to be called when the data in localStorage changes.
 * @param {T} args.dataFromStore - The initial data to be stored in localStorage.
 *
 * @returns {void}
 *
 * @example
 * ```typescript
 * const { key, handler, dataFromStore } = {
 *   key: 'myKey',
 *   handler: (data) => console.log(data),
 *   dataFromStore: { some: 'data' }
 * };
 * useLocalStorage({ key, handler, dataFromStore });
 * ```
 */
export const useLocalStorage = <T>(args: Args<T>) => {
    const { key, handler, dataFromStore } = args;

    useEffect(() => {
        const data = localStorage.getItem(key);
        if (data && JSON.parse(data) !== dataFromStore) {
            handler({ [key]: JSON.parse(data) });
        }
    }, [handler, key]);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(dataFromStore));
    }, [dataFromStore, key]);
};
