import { useGetAllLocations } from '../../../../api/useGetAllLocations';
import { ContainerSC } from '../styles';
import React, { useId } from 'react';
import { HeaderCreateTasks } from './components/HeaderCreateTasks';
import { useGetConnectorTypes } from '../../../../api/useGetConnectorTypes';
import { BodyCreateTasks } from './components/BodyCreateTasks';

export const CreateTasks = () => {
    useGetAllLocations();
    useGetConnectorTypes();

    return (
        <ContainerSC>
            <HeaderCreateTasks />
            <BodyCreateTasks />
        </ContainerSC>
    );
};
