import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle``

export const SectionSC = styled.section`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: max-content auto;
`