import styled from 'styled-components';

export const DivWrapperSolidguageSC = styled.div`
    display: flex;
    align-items: center;
`;

export const DivWrapperLabelsSC = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -20px;
    z-index: 1;
`;

export const ChartWrapper = styled.div`
    width: 200px;
    height: 200px;
`;

export const Value = styled.span`
    font-size: 24px;
    font-weight: 600;
    padding: 5px 0;
`;
