import React, { useDeferredValue, useEffect } from 'react';

import { emptyArray } from 'src/tools';

import { ContextMenu } from './components/ContextMenu';
import { defaultValueRenderer, defaultDataRenderer } from './defaultRenderers';
import { usePrepareTableProps } from './usePrepareTableProps';
import { FocusTableProps, Identity } from './interfaces';
import { CustomTrSC, DivWrapperTextSC, StyledCell, StyledHeaderCell, StyledTable, StyledTableWrapper } from './styles';
import { projectTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { CustomTdSC, DivActionCellSC, DivActionHeaderCellSC } from '../Chapters/Tasks/styles';
import { ArrowClockwise28Filled, Dismiss28Filled } from '@fluentui/react-icons';
import { Button, Tooltip } from '@fluentui/react-components';
import { colorsByNameStatus } from '../Chapters/Tasks/Monitoring/settings/colorsByNameStatus';
import { positionByNameColumn } from '../Chapters/Tasks/Monitoring/settings/positionByNameColumn';

const idStatusByNameStatus: { [key: string]: string } = {
    Success: 'success',
    'No receipts': 'noReceipts',
    Pending: 'pending',
    'Saved to bucket': 'savedToBucket',
};

export const FocusTable = <I extends string = string, R extends Identity<I> = Identity<I>>({
    maxHeight,
    listEnableRow = [],
    isDisabledRows = false,
    isShowCheckBox = false,
    isShowActions = false,
    isPositionCell = false,
    arrayCancelStatuses = [],
    taskDataByTaskId = null,
    ...propsG
}: FocusTableProps<I, R>) => {
    const {
        columnsOptions = emptyArray,
        headerCellRenderer,
        tableContextRef,
        contextMenuEvent,
        onMouseDown,
        selectRow,
        isSelectedAll = false,
        selectAllRow,
        cancelTask,
        reloadTask,
        selectedListRow = [],
        ...restProps
    } = usePrepareTableProps<I, R>({ ...propsG });
    const { colorBrandBackground2, colorNeutralForegroundStaticInverted } = projectTheme;
    // const isRtl = document.documentElement.getAttribute('dir') === 'rtl';
    // useEffect(() => {
    //     // console.log('isRtl', isRtl);
    //     // tableContextRef.current.isRtl = isRtl;
    // }, [isRtl]);
    const { t } = useTranslation();

    const cellRenderer = ({ cell, row, col, ...props }: any) => {
        const style = columnsOptions[col]?.style;
        const handleMouseDown = (event: MouseEvent) => {
            // event to show menu on selected range
            onMouseDown(event);

            if (event.button === 2) {
                // to show menu on selected range
                event.preventDefault();
                return;
            }
            // const selection = {
            //     start: { i: row, j: col },
            //     end: { i: row, j: col },
            // };
            // tableContextRef.current.selection = selection;
            // handlers should to work for other mouse buttons
            props.onMouseDown(event);
        };

        const getBGColor = () => {
            let isStatus = false;
            if (columnsOptions[col]) {
                isStatus = columnsOptions[col].id === 'status';
            }

            if (isStatus) {
                // @ts-ignore
                if (propsG.value[row]?.rowBGColor) {
                    if (idStatusByNameStatus[cell.value]) {
                        return colorsByNameStatus[idStatusByNameStatus[cell.value]];
                    }
                    return colorsByNameStatus.error;
                }
                return null;
            }
            return null;
        };

        if (columnsOptions[col]?.id === 'error') {
            return (
                <StyledCell
                    $bgColor={getBGColor()}
                    $align={isPositionCell ? positionByNameColumn[columnsOptions[col].id] : undefined}
                    data-row={row}
                    data-col={col}
                    {...props}
                    onMouseDown={handleMouseDown}
                    {...style}
                >
                    <Tooltip content={cell?.value || null} relationship="label">
                        <DivWrapperTextSC>{cell?.value || null}</DivWrapperTextSC>
                    </Tooltip>
                </StyledCell>
            );
        }

        return (
            <StyledCell
                $bgColor={getBGColor()}
                data-row={row}
                data-col={col}
                $align={isPositionCell ? positionByNameColumn[columnsOptions[col]?.id] || 'right' : undefined}
                {...props}
                onMouseDown={handleMouseDown}
                {...style}
            >
                {cell?.value || null}
            </StyledCell>
        );
    };

    const sheetRenderer = ({ ...props }: any) => {
        const onChecked = () => {
            selectAllRow && selectAllRow(!isSelectedAll);
        };
        return (
            <table className={props.className}>
                <thead>
                    <tr>
                        {isShowCheckBox && (
                            <StyledHeaderCell className="action-cell">
                                <input type="checkbox" checked={isSelectedAll} onChange={onChecked} />
                            </StyledHeaderCell>
                        )}
                        {isShowActions && (
                            <StyledHeaderCell className="action-cell">
                                <DivActionHeaderCellSC>{t('Task control')}</DivActionHeaderCellSC>
                            </StyledHeaderCell>
                        )}

                        {columnsOptions.map(headerCellRenderer)}
                    </tr>
                </thead>
                <tbody>{props.children}</tbody>
            </table>
        );
    };

    const rowRenderer = ({ ...props }: any) => {
        const onChecked = () => {
            if (isDisabledRows) {
                if (listEnableRow?.includes(props.cells[5].value)) {
                    selectRow && selectRow(props.row);
                }
            } else {
                selectRow && selectRow(props.row);
            }
        };
        const getBGOpacity = () => {
            if (isDisabledRows) {
                if (!listEnableRow?.includes(Number(propsG.value[props.row]?.id))) {
                    return '0.5';
                }
            }
            return '1';
        };

        const onClickDismiss = () => {
            cancelTask && cancelTask(Number(props.cells[0].value));
        };

        const onClickReload = () => {
            reloadTask && reloadTask(Number(props.cells[0].value));
        };

        const getBGColor = () => {
            // @ts-ignore
            // console.log('weffasdfweqfdsaf', propsG.value[props.row]?.rowBGColor);
            // @ts-ignore
            return selectedListRow.includes(props.row) ? colorBrandBackground2 : null;
        };

        // console.log('233r3rwqe', arrayCancelStatuses, props, propsG);

        let isShowCancelButton: boolean = false;

        if (taskDataByTaskId) {
            const found = arrayCancelStatuses?.find(
                (item) => item.statusCode === taskDataByTaskId[props.cells[0].value]?.statusCode,
            );
            if (found) {
                isShowCancelButton = true;
            }
        }

        return (
            <CustomTrSC $bgOpacity={getBGOpacity()} $bgColor={getBGColor()}>
                {isShowCheckBox && (
                    <td className="action-cell">
                        <input type="checkbox" checked={selectedListRow.includes(props.row)} onChange={onChecked} />
                    </td>
                )}

                {isShowActions && (
                    <CustomTdSC className="action-cell">
                        <DivActionCellSC>
                            {isShowCancelButton && (
                                <Tooltip content={t('Stop')} relationship="label">
                                    <Button
                                        appearance="subtle"
                                        onClick={onClickDismiss}
                                        icon={<Dismiss28Filled color={'red'} />}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip content={t('Reload')} relationship="label">
                                <Button appearance="subtle" onClick={onClickReload} icon={<ArrowClockwise28Filled />} />
                            </Tooltip>
                        </DivActionCellSC>
                    </CustomTdSC>
                )}
                {props.children}
            </CustomTrSC>
        );
    };

    const defferedData = useDeferredValue(restProps.data);

    return (
        <StyledTableWrapper maxHeight={maxHeight}>
            {/*// @ts-ignore*/}
            <StyledTable
                {...restProps}
                valueRenderer={defaultValueRenderer}
                cellRenderer={cellRenderer}
                dataRenderer={defaultDataRenderer}
                sheetRenderer={sheetRenderer}
                data={defferedData}
                rowRenderer={rowRenderer}
            />

            <ContextMenu contextRef={tableContextRef} contextMenuEvent={contextMenuEvent} />
        </StyledTableWrapper>
    );
};
