import { ColumnOptions } from '../../../../FocusTable';

export const columnsOptions: ColumnOptions[] = [
    {
        id: 'id',
        label: 'Task id',
        // visible: false,
    },
    {
        id: 'connectorId',
        label: 'Connector id',
        // visible: false
    },
    {
        id: 'tenantId',
        label: 'Tenant id',
        // visible: false
    },

    { id: 'tenantName', label: 'Tenant name' },
    { id: 'PLId', label: 'Project location id', visible: false },

    { id: 'PLName', label: 'Location' },
    { id: 'taskSource', label: 'Task source', visible: false },

    // { id: 'posName', minWidth: 200, title: 'Pos Details' },
    { id: 'connectorType', label: 'Connector type' },
    { id: 'dateFrom', label: 'Date' },
    // { id: 'dateTo', label: 'Date to' },
    { id: 'status', label: 'Status' },
    { id: 'billsNumber', label: 'Bills number' },

    { id: 'isIsolated', label: 'Isolated workers', visible: false },
    { id: 'priority', label: 'Priority', visible: false },
    { id: 'attempt', label: 'Attempt', visible: false },

    { id: 'createdAt', label: 'Created at' },
    { id: 'startedAt', label: 'Started at', visible: false },
    { id: 'delayedTo', label: 'Delayed to', visible: false },
    { id: 'duration', label: 'Duration', visible: false },

    { id: 'error', label: 'Error', style: { maxWidth: 200 } },
];
