import { UrlsByServices, Services, Protocol, ValueTypesOf, PickPureFieldType } from 'src/types';

const prepareCoreAdminService = (serverName: string): PickPureFieldType<UrlsByServices, 'core/admin-service'> => ({
    AUTH_URL: `https://${serverName}/sso/ajax-token`,
    PROJECTS_URL: `https://${serverName}/api/projects/`,
    PROJECT_LOCATIONS_URL: `https://${serverName}/api/project-locations/`,
    AUTH_SAML_URL: `https://${serverName}/saml/`,
    LOGOUT_URL: `https://${serverName}/admin/logout/`,
    PL_BY_ML_URL: `https://${serverName}/api/pl-by-ml/`,
    CATEGORIES_URL: `https://${serverName}/api/categories`,
    LEVENSTEIN_URL: `https://${serverName}/api/categories/levenshtein-comparison-arr/`,
    GROUP_DATA_OBJECTS_URL: `https://${serverName}/api/categories/group-data-objects/`,
    PLACES_URL: `https://${serverName}/api/places/`,
    TENANTS_URL: `https://${serverName}/api/tenants/`,
    TENANT_2_PLACE_URL: `https://${serverName}/api/tenant-2-place/`,
});

const prepareCoreMapServiceBack = (serverName: string): PickPureFieldType<UrlsByServices, 'core/map-service-back'> => ({
    LOCATIONS_URL: `https://${serverName}/api/locations/`,
    LOCATION_CLONE_URL: `https://${serverName}/api/location-clone/`,
    PLANS_URL: `https://${serverName}/api/plans/`,
    LAYERS_URL: `https://${serverName}/api/layers/`,
    VERSIONS_URL: `https://${serverName}/api/versions/`,
});

const prepareCoreStructureService = (
    serverName: string,
): PickPureFieldType<UrlsByServices, 'core/structure-service'> => ({
    CURRENT_URL: `https://${serverName}/core-current/`,
    LOCATIONS_URL: `https://${serverName}/structure-service/v1/project_locations/`,
    CACHED_STRUCTURE_URL: `https://${serverName}/structure-service/v1/cached_structure/`,
    METRIC_DYNAMICS_URL: `https://${serverName}/core-data-provider/v1/metric_dynamics/`,
    // METRIC_DYNAMICS_URL: `https://${serverName}/core-data-provider/v1/metric_dynamics`,
    AVERAGE_INTERSECTIONS_REQUEST_URL: `https://${serverName}/core-data-provider/v1/shopster_awgweighted_intersections_percent_obj_with_arr/`,
    PAIWISE_INTERSECTIONS_URL: `https://${serverName}/core-data-provider/v1/shopster_paiwise_intersections_obj_with_arr/`,
    METRICS_LIST_URL: `https://${serverName}/structure-service/v1/metrics-list/`,
    STANDARD_CATEGORIES_LIST_URL: `https://${serverName}/structure-service/v1/standard-categories-list/`,
});

const prepareFsfService = (serverName: string): PickPureFieldType<UrlsByServices, 'fsf/metric-storage'> => ({
    TENANTS_URL: `https://${serverName}/api/tenants/`,
    CONNECTORS_URL: `https://${serverName}/api/connectors/`,
    MANAGE_CONNECTORS: `https://${serverName}/api/manage-connectors/`,
    CONNECTOR_PARAMS: `https://${serverName}/api/connector-params/`,
    POINTS_OF_SALE: `https://${serverName}/api/points-of-sale/`,
    VALIDATE_TENANT_DATA: `https://${serverName}/api/validate-tenant-data/`,
    LOAD_TENANT_DATA: `https://${serverName}/api/load-tenant-data/`,
    TASK_CREATOR: `https://${serverName}/api/task-creator/`,
    CONNECTOR_TASK: `https://${serverName}/api/connector-task/`,
    TASK_CANCELER: `https://${serverName}/api/task-canceler/`,
});

const prepareAppBackendUrlsByProtocol =
    (protocol: Protocol) =>
    (serverName: string): PickPureFieldType<UrlsByServices, 'app/app-backend'> => ({
        CABINET_PREFERENCES_URL: `${protocol}://${serverName}/api/user-settings/cabinet-preferences/`,
        PROJECT_ALERTS_URL: `${protocol}://${serverName}/api/user-interaction/project-alerts/`,
        ACCOUNT_PARAMETERS_URL: `${protocol}://${serverName}/api/user-settings/account-parameters/`,
        USER_PRESETS_URL: `${protocol}://${serverName}/api/presets/user-presets/`,
        SHARED_PRESETS_URL: `${protocol}://${serverName}/api/presets/shared-presets/`,
        LOCATION_EVENTS_URL: `${protocol}://${serverName}/api/location-events/location-events/`,
        LOCATION_EVENT_TYPES_URL: `${protocol}://${serverName}/api/location-events/location-event-types/`,
        MANAGEMENT_DECISIONS_URL: `${protocol}://${serverName}/api/management-decisions/management-decisions/`,
        MANAGEMENT_DECISION_TYPES_URL: `${protocol}://${serverName}/api/management-decisions/management-decision-types/`,
        RATINGS_URL: `${protocol}://${serverName}/api/ratings/ratings/`,
        DEFAULT_DASHBOARDS_URL: `${protocol}://${serverName}/api/dashboards/default-dashboards/`,
        PROJECT_DASHBOARDS_URL: `${protocol}://${serverName}/api/dashboards/project-dashboards/`,
        USER_DASHBOARDS_URL: `${protocol}://${serverName}/api/dashboards/user-dashboards/`,
        BACKGROUND_REPORTS_URL: `${protocol}://${serverName}/api/background-reports/`,
        CREATE_BACKGROUND_REPORT_URL: `${protocol}://${serverName}/api/create-background-report/`,
        BACKGROUND_REPORT_TYPES_URL: `${protocol}://${serverName}/api/background-report-types/`,
    });

export const getServicesStructure = (services: Services, appBackendHost?: string): UrlsByServices => {
    const prepareUrlsMap = {
        'core/admin-service': prepareCoreAdminService,
        'core/map-service-back': prepareCoreMapServiceBack,
        'core/structure-service': prepareCoreStructureService,
        'fsf/metric-storage': prepareFsfService,
        'app/app-backend': prepareAppBackendUrlsByProtocol(appBackendHost ? 'http' : 'https'),
    } as Record<string, (protocol: string) => ValueTypesOf<UrlsByServices>>;

    const result = Object.entries(services).reduce((acc, [service, serverName]) => {
        const serviceDataFabric = prepareUrlsMap[service];
        const preparedUrls = serviceDataFabric ? serviceDataFabric(serverName) : {};
        return {
            ...acc,
            [service]: preparedUrls,
        };
    }, {} as UrlsByServices);

    return result;
};
