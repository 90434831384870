import { ContainerSC } from '../styles';
import { HeaderMonitoring } from './components/HeaderMonitoring';
import { useGetAllLocations } from '../../../../api/useGetAllLocations';
import { useGetConnectorTypes } from '../../../../api/useGetConnectorTypes';
import { BodyMonitoring } from './components/BodyMonitoring';

export const Monitoring = () => {
    useGetAllLocations();
    useGetConnectorTypes();

    return (
        <ContainerSC>
            <HeaderMonitoring />
            <BodyMonitoring />
        </ContainerSC>
    );
};
