import { Label, PopoverProps } from '@fluentui/react-components';
import { IParamsOnChangeSelection, TOption } from '../interfaces';
import { DivWrapperInputAndLabelSC } from '../styles';
import { Select } from '../../../Select/Select';
import React from 'react';
import { TLocations } from '../../../../types';

export const LocationsSelect = (props: {
    labelG: string;
    label: string;
    open: boolean;
    onOpenChange: PopoverProps['onOpenChange'];
    onChangeSelection: (params: IParamsOnChangeSelection) => void;
    listOptions: TOption[];
    selectedIds: number[] | string[];
    keyName: string;
    selectedLocations: TLocations;
    allLocations: TLocations;
}) => {
    const {
        labelG,
        label,
        open,
        onOpenChange,
        onChangeSelection,
        listOptions,
        selectedIds,
        keyName,
        selectedLocations,
        allLocations,
    } = props;

    const onSelection = (option: TOption) => {
        const isSelected = selectedLocations.some((item) => item.id === option.id);
        const newSelectedLocations = isSelected
            ? selectedLocations.filter((item) => item.id !== option.id)
            : [...selectedLocations, allLocations.find((item) => item.id === option.id)!];

        onChangeSelection({
            [keyName]: newSelectedLocations,
        });
    };

    const selectAll = () => {
        onChangeSelection({
            [keyName]: [...allLocations],
        });
    };

    const clearAll = () => {
        onChangeSelection({
            [keyName]: [],
        });
    };

    return (
        <DivWrapperInputAndLabelSC>
            <Label>{labelG}</Label>
            <Select
                label={label}
                open={open}
                onOpenChange={onOpenChange}
                onChangeSelection={onSelection}
                listOptions={listOptions}
                isMulti={true}
                selectedIds={selectedIds}
                selectAll={selectAll}
                clearAll={clearAll}
                isShowButtons={true}
            />
        </DivWrapperInputAndLabelSC>
    );
};
