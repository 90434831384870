import { Route, Routes, Navigate } from 'react-router-dom';
import { Projects } from './components/Projects/Projects';
import React, { FC } from 'react';
import { Locations } from './components/Locations/Locations';
import { Connectors } from './components/Connectors/Connectors';
import { CreateTasks } from './components/Chapters/Tasks/CreateTasks/CreateTasks';
import { Monitoring } from './components/Chapters/Tasks/Monitoring/Monitoring';

export const AppRouter: FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/configuration/connectors/projects" />} />
            <Route path="/configuration/connectors/projects" element={<Projects />} />
            <Route path="/configuration/connectors" element={<Navigate to="/configuration/connectors/projects" />} />
            <Route path="/configuration/connectors/project/:projectId" element={<Locations />} />
            <Route path="/configuration/connectors/project/:projectId/pl/:plId" element={<Connectors />} />
            <Route path="/tasks/create" element={<CreateTasks />} />
            <Route path="/tasks/monitoring" element={<Monitoring />} />
            <Route path="/service/monitoring" element={null} />
        </Routes>
    );
};
