import { TASKS_STORE, TENANTS } from '../constants';
import { useGeneralStore } from '../store';
import { TConnectorReq } from '../types/connectors';
import { IParamsUseGetTenants, UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTaskStore } from '../store/useTaskStore';
import { useAddGlobalSpinner } from '../hooks';
import { IResponseTenant, ITenant } from '../types/tasks';

export const useGetTenants = (obj: IParamsUseGetTenants, status: boolean) => {
    const queryKey = [TASKS_STORE, TENANTS, obj.plIds];
    const { offset, limit, plIds } = obj;
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { updateTasksStore, getTasksStore } = useTaskStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: IResponseTenant) => response;

    const enabled = obj.plIds.length > 0 && Boolean(status);

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].TENANTS_URL}?limit=${limit}&offset=${offset}&project_location_ids=${plIds.join(',')}`;
    };
    const queryFn = () =>
        getRequest({
            url: getUrl(services),
            token: token,
            onError: (error) => {
                updateGeneralStore({
                    messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4(),
                        },
                    ],
                });
            },
        });

    const { data, isFetching, error, refetch } = useQuery<IResponseTenant, unknown, IResponseTenant, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        const tenantDataByTenantId: { [key: number]: ITenant } = {};

        data.results.forEach((item) => {
            if (item.id) {
                tenantDataByTenantId[item.id] = item;
            }
        });

        updateTasksStore({
            tenants: data.results,
            resTenants: data,
            tenantDataByTenantId,
        });
    }, [data, updateGeneralStore]);
    useAddGlobalSpinner({ isFetching, text: 'Loading tenants' });
    return { isFetching, refetch };
};
