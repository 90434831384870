import styled from 'styled-components';
import Split from 'react-split'
import {
    Button,
    Dropdown, Label,
    makeStyles,
} from '@fluentui/react-components';
import { List } from '@fluentui/react-list-preview';


export const DivWrapperSideSC = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content 1fr;
    padding: 10px;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
`

export const StyledSplit = styled(Split)`
    display: flex;
    flex-direction: row;
    height: 100%;

    .gutter {
        background-color: #eee;
        background-repeat: no-repeat;
        background-position: 50%;
        z-index: 1;
    }

    .gutter-horizontal {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
        cursor: col-resize;
    }
`;

export const DivWrapperOptionsPanelSC = styled.div`
    display: grid;
    height: max-content;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    
`

export const DivWrapperCheckBoxesSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: max-content max-content;
    grid-template-rows: 100%;
    gap: 10px;
`

export const DivWrapperFilterSC = styled.div<{
    $itemWidth?:number | undefined;
}>`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: repeat(auto-fill, ${({$itemWidth}) => $itemWidth ? $itemWidth : 280}px);
    grid-template-rows: 32px;
    gap: 10px;
    //grid-auto-flow: row dense;
`
export const DivWrapperFilterConnectorsListSC = styled.div<{
    $itemWidth?:number | undefined;
}>`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: 32px;
    gap: 10px;
    justify-content: space-between;
    //grid-auto-flow: row dense;
`

export const DivWrapperSelectSC = styled.div`
    display: grid;
    width: max-content;
    height: 100%;
    grid-template-columns: max-content 200px;
    grid-template-rows: 100%;
    gap: 5px;
    label {
        display: grid;
        align-items: center;
        justify-items: center;
    }
`

export const InputFilterWrapperSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    box-sizing: border-box;
    gap: 15px;
`

export const DivBoxFilterSC = styled.div<{
    $position: string;
    $count: number;
}>`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(${({$count}) => $count}, max-content);
    grid-template-rows: 100%;
    gap: 10px;
    justify-content: ${({$position}) => $position};
`

export const DivWrapperInputsBaseSC = styled.div`
    display: grid;
    width: calc(100vw - 200px);
    height: max-content;
    grid-template-columns: repeat(auto-fill, 350px);
    grid-auto-rows: max-content;
    gap: 10px;
`

export const DivWrapperInput = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
`

export const DivWrapperCheckboxSC = styled.div`
    display: grid;
    position: relative;
    width: 100%;
    height: 32px;
`

export const DivWrapperCheckboxInner = styled.div`
    display: grid;
    position: absolute;
    left: -8px;
`

export const DivWrapperInputsCustomSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: repeat(auto-fill, 350px);
    grid-auto-rows: max-content;
    gap: 10px;
`

export const DivWrapperInputListsSC = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    gap: 10px;
`

export const DivWrapperButtonSC = styled.div`
    display: grid;
    width: 100%;
    height: 32px;
    grid-template-columns: 100px;
    grid-template-rows: 32px;
    justify-content: end;
`

export const DivWrapperDropdownSC = styled.div`
    display: grid;
    grid-template-columns: auto 32px;
    grid-template-rows: 100%;
    gap: 5px;
    height: max-content;
`

export const DivWrapperDropdownAndLabelSC = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20px auto;
    
`

export const CustomDropdownSC = styled(Dropdown)`
    min-width: 100px;
`

export const DivWrapperButton2SC = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20px 32px;
`

export const DivSpinnerBoxSC = styled.div`
    display: grid;
    position: absolute;
    width: 32px;
    height: 100%;
    //background: rgba(255,255,255,.5);

`
export const ButtonWithSpinnerSC = styled(Button)`
    position: relative;
`

export const useStyles = makeStyles({
    control: {
        maxWidth: "400px",
    },
});

export const DivBoxSearchSC = styled.div`
    display: grid;
`

export const DivWrapperListPopoverSC = styled.div`
    display: grid;
    width: 300px;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: 32px auto;
`
export const DivWrapperListPopoverScrollSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    overflow: auto;
    max-height: 200px;
`

export const DivWrapperCheckBoxListSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: max-content auto;
`

export const DivWrapperListSC = styled.div`
    display: grid;
    height: 400px;
    width: 100%;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
`

export const DivItemCheckboxSC = styled.div`
    display: grid;
    height: max-content;
    width: max-content;
`

export const DivWrapperListPopoverForListTimesSC = styled.div`
    display: grid;
    width: 300px;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    overflow: auto;
`

export const DivSpinnerBox2SC = styled.div`
    display: grid;
    width: 32px;
    height: 32px;
    //background: rgba(255,255,255,.5);

`

export const DivWrapperSelectConnectorTypeSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 200px;
`

export const DivWrapper2ButtonSC = styled.div`
    display: grid;
    width: 100%;
    height: 32px;
    grid-template-columns: 100px 100px;
    grid-template-rows: 32px;
    justify-content: end;
    gap: 5px;
`

export const DivWrapperInputsCustomAndButtonSC = styled.div`
    display: grid;
    width: calc(100vw - 200px);
    height: max-content;
    grid-template-columns: auto max-content;
    grid-template-rows:  max-content;
    gap: 10px;
    align-items: end;
`

export const CustomLabelSC = styled(Label)<{
    colorText: string;
}>`
    color: ${({colorText}) => colorText === '' ? null : colorText};
`

export const DivContentDuplicateModalSC = styled.div`
    display: grid;
    width: 500px;
    height: max-content;
    grid-template-rows: max-content max-content max-content;
    gap: 10px;
`

export const useStylesInput = makeStyles({
    root: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px",
        width: "100%",
        // Prevent the example from taking the full width of the page (optional)
        // maxWidth: "400px",
        alignItems: 'flex-start'
    },
});