import { TListSettings } from '../../interfaces';

const initialListSettingsTable: TListSettings = [
    {
        name: 'Active',
        id: 'active',
        checked: false,
    },
];

export default initialListSettingsTable;
