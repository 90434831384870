import { ChartWrapper, DivWrapperLabelsSC, DivWrapperSolidguageSC, Value } from './styles';
import * as Highcharts from 'highcharts';
import { FC, useRef } from 'react';
import { IPropsSolidguage } from './interfaces';
import moreHicharts from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { HighchartsReact } from 'highcharts-react-official';
import { Label } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
moreHicharts(Highcharts);
solidGauge(Highcharts);

export const Solidguage: FC<IPropsSolidguage> = (props) => {
    const { options, statuses } = props;
    const { t } = useTranslation();

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return (
        <DivWrapperSolidguageSC>
            <ChartWrapper>
                <HighchartsReact highcharts={Highcharts} ref={chartComponentRef} {...props} />
            </ChartWrapper>
            <DivWrapperLabelsSC>
                <Value style={{}}>
                    <b>{statuses.count}</b>
                </Value>
                <Value
                    style={{
                        borderTop: '2px solid var(--color-secondary-dark)',
                        color: 'var(--color-secondary-darker)',
                    }}
                >{`${statuses.number}%`}</Value>
            </DivWrapperLabelsSC>
        </DivWrapperSolidguageSC>
    );
};
