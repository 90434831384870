import styled from 'styled-components';

export const SidebarWrapperSC = styled.div`
    display: grid;
    width: 200px;
    height: 100%;
`;

export const CustomTreeLayoutSC = styled.div<{
    $colorHover: string;
    $colorActive: string;
    $disabled: boolean;
}>`
    display: grid;
    width: 100%;
    height: 30px;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    text-align: left;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    padding-left: 30px;
    box-sizing: border-box;
    align-content: center;
    background: ${({ $colorActive }) => ($colorActive ? $colorActive : null)};
    &:hover {
        background: ${({ $colorHover }) => $colorHover};
    }
`;

export const CustomSectionTreeSC = styled.div`
    display: grid;
    text-transform: uppercase;
`;
