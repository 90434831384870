import { IColumn } from '../../../../../types';
import { createTableColumn } from '@fluentui/react-components';
import { IItemForTable } from '../../../../Table/interfaces';

export const columnsSelectedTasks: IColumn[] = [
    createTableColumn<IItemForTable>({
        columnId: 'connector id',
        compare: (a: IItemForTable, b: IItemForTable) => {
            return String(a.id.label).localeCompare(String(b.id.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: 'connector type',
        compare: (a: IItemForTable, b: IItemForTable) => {
            return String(a.connector_type.label).localeCompare(String(b.connector_type.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: 'date',
        compare: (a: IItemForTable, b: IItemForTable) => {
            return String(a.date.label).localeCompare(String(b.date.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: 'tenant id',
        compare: (a: IItemForTable, b: IItemForTable) => {
            return String(a.tenantId.label).localeCompare(String(b.tenantId.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: 'status',
        compare: (a: IItemForTable, b: IItemForTable) => {
            return String(a.status.label).localeCompare(String(b.status.label));
        },
    }),
];
