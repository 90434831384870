import { Field, Label } from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import React from 'react';

export const DatePickerTo = (props: {
    htmlFor: string;
    label: string;
    styles: Record<string | number, string>;
    onSelectDate: (date: Date | null | undefined) => void;
    formatDate: (date?: Date | undefined) => string | 'Invalid DateTime';
    selectedDateTo: string | null;
    maxDateForDateToCalendar: Date;
}) => {
    const { htmlFor, label, styles, onSelectDate, formatDate, selectedDateTo, maxDateForDateToCalendar } = props;
    return (
        <div>
            <Label htmlFor={htmlFor}>{label}</Label>
            <Field id={htmlFor}>
                <DatePicker
                    className={styles.control}
                    placeholder={'label'}
                    onSelectDate={onSelectDate}
                    formatDate={formatDate}
                    value={selectedDateTo ? new Date(selectedDateTo) : null}
                    maxDate={maxDateForDateToCalendar}
                />
            </Field>
        </div>
    );
};