import { TListSettings } from '../../interfaces';

const initialListSettingsTable: TListSettings = [
    {
        name: 'Show task source',
        id: 'showTaskSource',
        checked: false,
    },
    {
        name: 'Show started at',
        id: 'showStartedAt',
        checked: false,
    },
    {
        name: 'Show project location id',
        id: 'showPLId',
        checked: false,
    },
    {
        name: 'Show isolated workers',
        id: 'showIsolatedWorkers',
        checked: false,
    },
    {
        name: 'Show priority',
        id: 'showPriority',
        checked: false,
    },
    {
        name: 'Show attempt',
        id: 'showAttempt',
        checked: false,
    },
    {
        name: 'Show delayed to',
        id: 'showDelayedTo',
        checked: false,
    },
    {
        name: 'Show duration',
        id: 'showDuration',
        checked: false,
    },
];

export default initialListSettingsTable;
