import { DivWrapperInputAndLabelSC } from '../styles';
import { Label } from '@fluentui/react-components';
import { Search } from '../../../Search/Search';
import React from 'react';

export const TenantsFilter = (props: { label: string; handleChange: (value: string) => void; value: string }) => {
    const { label, handleChange, value } = props;
    return (
        <DivWrapperInputAndLabelSC>
            <Label>{label}</Label>
            <Search placeholder={label} handleChange={handleChange} value={value} />
        </DivWrapperInputAndLabelSC>
    );
};
