import styled from 'styled-components';
import { MenuItem } from '@fluentui/react-components';

import { SortOrder } from '../interfaces';

export const ContextualMenuBodyWrapper = styled.div`
    padding: 5px;
`;

export const StyledMenuItem = styled(MenuItem)`
    :hover {
        color: var(--color-primary-darker) !important;
    }
`;

export const StyledIcon = styled.span<{ sortOrder?: SortOrder }>`
    cursor: pointer;
    display: flex;
    color: var(--color-primary);
    :hover {
        color: var(--color-primary-darker);
    }
`;

export const StyledHeaderCellContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    /* gap: 8px; */
    align-items: center;
    justify-content: center;
`;
