import { CustomSectionTreeSC, CustomTreeLayoutSC, SidebarWrapperSC } from './styles';
import { useEffect, useState } from 'react';
import { TMenu } from './interfaces';
import generateMenu from './tools/generateMenu';
import { sidebarConfig } from './sidebarConfig';
import { Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components';
import { projectTheme } from '../../theme';
import { useLocation, useNavigate } from 'react-router-dom';

export const Sidebar = () => {
    const [menu, setMenu] = useState<TMenu>([]);
    const location = useLocation();
    const [activeSubsection, setActiveSubsection] = useState<string>('');
    const [activeSections, setActiveSections] = useState<string>('');

    const navigate = useNavigate();
    useEffect(() => {
        setMenu(generateMenu({ sidebarConfig }));
    }, []);

    useEffect(() => {
        setActiveSubsection(location.pathname.split('/')[2]);
        setActiveSections(location.pathname.split('/')[1]);
    }, [location]);

    const onClick = (data: string) => () => {
        navigate(data);
    };

    return (
        <SidebarWrapperSC>
            <Tree defaultOpenItems={['Configuration', 'Tasks', 'Service']} aria-label="Default">
                {menu.map((item, i) => {
                    const nodeComponents = item.subsections?.map((item2, j) => {
                        const getColor = () => {
                            if (item.name.toLowerCase() === activeSections) {
                                if (activeSubsection === item2.name.toLowerCase()) {
                                    return projectTheme.colorBrandBackground2Pressed;
                                }
                            }
                            return '';
                        };
                        return (
                            <TreeItem value={item2.name} key={`subsections${j}`} itemType="leaf">
                                <CustomTreeLayoutSC
                                    onClick={!item2.disabled ? onClick(item2.servicePath) : undefined}
                                    $colorHover={projectTheme.colorBrandBackground2}
                                    $colorActive={getColor()}
                                    $disabled={item2.disabled}
                                >
                                    {item2.name}
                                </CustomTreeLayoutSC>
                            </TreeItem>
                        );
                    });
                    return (
                        <TreeItem key={`sections${i}`} itemType="branch" value={item.name}>
                            <TreeItemLayout>
                                <CustomSectionTreeSC>{item.name}</CustomSectionTreeSC>
                            </TreeItemLayout>
                            <Tree>{nodeComponents}</Tree>
                        </TreeItem>
                    );
                })}
            </Tree>
        </SidebarWrapperSC>
    );
};
