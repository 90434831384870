import { DateTime } from 'luxon';
import { Dayjs } from 'dayjs';

/**
 * Функци возвращает форматированную дату или null
 * @param {Dayjs | null} date Дата
 * @param {string} format Формат даты
 * @return {string | null} Возврат отформатированной даты
 * */

export const getFormatDate = (date: Dayjs | null, format: string) => {
    if (date) {
        return String(DateTime.fromJSDate(new Date(date?.toDate())).toFormat(format));
    }
    return null;
};
