import { ICancelStatus } from '../../interfaces';

export const arrayCancelStatuses: ICancelStatus[] = [
    {
        statusCode: -4,
        name: 'pending',
    },
    {
        statusCode: -6,
        name: 'fsf_err',
    },
    {
        statusCode: -7,
        name: 'cred_err',
    },
    {
        statusCode: -8,
        name: 'ofd_err',
    },
];
