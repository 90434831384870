/**
 * Custom hook that initializes and synchronizes the selected locations with local storage.
 *
 * This hook uses the `useLocalStorage` hook to persist the selected locations in local storage.
 * It retrieves the current selected locations from the task store and updates the task store
 * whenever the local storage changes.
 *
 * @returns {void}
 */
import { useLocalStorage } from './useLocalStorage';
import { TConnectorTypes, TLocations } from 'src/types';
import { useTaskStore } from 'src/store/useTaskStore';
import { TListSettings } from '../components/Chapters/Tasks/interfaces';

export const useInitialFills = () => {
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const {
        selectedLocations,
        listSettingsTable,
        selectedTypesConnector,
        listSettingsTableMonitoring,
        listTaskStatusesFilter,
        selectedDateTo,
        selectedDateFrom,
        selectedCreatedFrom,
        selectedCreatedTo,
        selectedMonitoringDateFrom,
        selectedDateMonitoringTo,
    } = getTasksStore();

    useLocalStorage<TLocations>({
        key: 'selectedLocations',
        handler: updateTasksStore,
        dataFromStore: selectedLocations,
    });
    useLocalStorage<TListSettings>({
        key: 'listSettingsTable',
        handler: updateTasksStore,
        dataFromStore: listSettingsTable,
    });
    useLocalStorage<TConnectorTypes>({
        key: 'selectedTypesConnector',
        handler: updateTasksStore,
        dataFromStore: selectedTypesConnector,
    });
    useLocalStorage<TListSettings>({
        key: 'listSettingsTableMonitoring',
        handler: updateTasksStore,
        dataFromStore: listSettingsTableMonitoring,
    });
    useLocalStorage<TListSettings>({
        key: 'listTaskStatusesFilter',
        handler: updateTasksStore,
        dataFromStore: listTaskStatusesFilter,
    });
    useLocalStorage<string | null>({
        key: 'selectedDateTo',
        handler: updateTasksStore,
        dataFromStore: selectedDateTo,
    });
    useLocalStorage<string | null>({
        key: 'selectedDateFrom',
        handler: updateTasksStore,
        dataFromStore: selectedDateFrom,
    });
    useLocalStorage<string | null>({
        key: 'selectedCreatedFrom',
        handler: updateTasksStore,
        dataFromStore: selectedCreatedFrom,
    });
    useLocalStorage<string | null>({
        key: 'selectedCreatedTo',
        handler: updateTasksStore,
        dataFromStore: selectedCreatedTo,
    });
    useLocalStorage<string | null>({
        key: 'selectedMonitoringDateFrom',
        handler: updateTasksStore,
        dataFromStore: selectedMonitoringDateFrom,
    });
    useLocalStorage<string | null>({
        key: 'selectedDateMonitoringTo',
        handler: updateTasksStore,
        dataFromStore: selectedDateMonitoringTo,
    });
};
