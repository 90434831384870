import React from 'react';


import { emptyArray, toString } from 'src/tools';

import { TableFilter, MenuContextArgs, MenuConfig, Identity } from '../interfaces';
import { xlsxExport } from '../xlsxExport';

import { SelectionRanger } from './SelectionRanger';
import { Search } from '../../Search/Search';

export type MenuItemRenderer<
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
> = (args: MenuContextArgs<I, R>) => React.ReactNode;

const createComparableInputFilter = (comparer: (lhv: unknown, rhv: unknown) => boolean) => {
    function inputRenderer<
        I extends string = string, // id type
        R extends Identity<I> = Identity<I>,
    >({ cell, context, menuItemId }: MenuContextArgs<I, R>): React.ReactNode {
        const currentFilter = context.filters.find(
            (filter) => filter.columnId === cell.column.id && filter.filterId === menuItemId,
        );
        const filterValue = currentFilter?.filterValue?.toString() || '';

        const changeValue = (value: string) => {
            context.changeFilter((prevFilters: TableFilter[]) => {
                const withoutCurrentFilter = prevFilters.filter(
                    (filter) => filter.columnId !== cell.column.id || filter.filterId !== menuItemId,
                );

                if (!value?.length) {
                    return withoutCurrentFilter;
                }

                return [
                    ...withoutCurrentFilter,
                    {
                        columnId: cell.column.id,
                        filterId: menuItemId,
                        filterValue: value,
                        filterPredicate: (dataValue) => comparer(dataValue?.toString(), value),
                    },
                ];
            });
        };

        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Escape') {
                context.closeMenu();
            }

            if (event.key !== 'Enter') {
                return;
            }
            const currentFilter = context.filters.find(
                (filter) => filter.columnId === cell.column.id && filter.filterId === menuItemId,
            );
            const filterValue = currentFilter?.filterValue?.toString() || '';

            const prevFilters = context.filters;

            const withoutCurrentFilter = prevFilters.filter(
                (filter) => filter.columnId !== cell.column.id || filter.filterId !== menuItemId,
            );
            const newFilters = [
                ...withoutCurrentFilter,
                {
                    columnId: cell.column.id,
                    filterId: menuItemId,
                    filterValue,
                    filterPredicate: (dataValue: unknown) => comparer(dataValue, filterValue),
                },
            ];

            context.changeFilter(newFilters);
            context.closeMenu();
        };
        return <Search onKeyDown={handleKeyDown} value={filterValue} handleChange={changeValue} />;
    }

    return inputRenderer;
};

const createItemRendererWithComparableFilter =
    (comparer: (lhv: unknown, rhv: unknown) => boolean) =>
    <
        I extends string = string, // id type
        R extends Identity<I> = Identity<I>,
    >(
        args: MenuContextArgs<I, R>,
    ) => {
        function itemRenderer<
            I extends string = string, // id type
            R extends Identity<I> = Identity<I>,
        >({ cell, context, menuItemId, label }: MenuContextArgs<I, R>): React.ReactNode {
            const filterValue = cell.value?.toString();

            const handleClick = () => {
                context.changeFilter((prevFilters: TableFilter[]) => {
                    const withoutCurrentFilter = prevFilters.filter(
                        (filter) => filter.columnId !== cell.column.id || filter.filterId !== menuItemId,
                    );
                    return [
                        ...withoutCurrentFilter,
                        {
                            columnId: cell.column.id,
                            filterId: menuItemId,
                            filterValue,
                            filterPredicate: (dataValue) => comparer(dataValue?.toString(), filterValue),
                        },
                    ];
                });
                context.closeMenu();
            };

            return (
                <span onClick={handleClick}>
                    {label || menuItemId} "{toString(cell.value)}"
                </span>
            );
        }

        return itemRenderer(args);
    };

export const getMenuConfig = <
    I extends string = string, // id type
    R extends Identity<I> = Identity<I>,
>() => {
    const menuItems: MenuConfig<I, R> = [
        {
            id: 'filters',
            label: 'Filters',
            groupItems: [
                {
                    id: 'include',
                    label: 'Include',
                    subItems: [
                        {
                            id: 'includeInput',
                            label: 'Include input',
                            renderer: createComparableInputFilter(
                                (lhv, rhv) => toString(lhv).includes?.(toString(rhv)) || false,
                            ),
                        },
                        {
                            id: 'includeValue',
                            label: 'Include value',
                            renderer: createItemRendererWithComparableFilter(
                                (lhv, rhv) => toString(lhv) === toString(rhv),
                            ),
                        },
                    ],
                },
                {
                    id: 'exclude',
                    label: 'Exclude',
                    subItems: [
                        {
                            id: 'excludeInput',
                            label: 'Exclude input',
                            renderer: createComparableInputFilter(
                                (lhv, rhv) => !toString(lhv).includes?.(toString(rhv)),
                            ),
                        },
                        {
                            id: 'excludeValue',
                            label: 'Exclude value',
                            renderer: createItemRendererWithComparableFilter(
                                (lhv, rhv) => !toString(lhv).includes?.(toString(rhv)),
                            ),
                        },
                    ],
                },
                {
                    id: 'removeAllFilters',
                    label: 'Remove all filters',
                    onItemClick: ({ context }) => context.changeFilter(emptyArray as TableFilter[]),
                },
            ],
        },
        null,
        {
            id: 'operations',
            label: 'Operations',

            groupItems: [
                {
                    id: 'selection',
                    label: 'Selection',
                    subItems: [
                        {
                            id: 'expandColumnsSelection',
                            label: 'Expand columns selection',
                            onItemClick: ({ context }) => {
                                const { changeSelection, data, visibleColumns, selection } = context;

                                if (!selection || !data?.length || !visibleColumns.length) {
                                    return;
                                }

                                const tableRange = {
                                    start: { i: 0, j: selection.start.j },
                                    end: { i: data.length - 1, j: selection.end.j },
                                };

                                changeSelection?.(tableRange);
                            },
                        },
                        {
                            id: 'selectAll',
                            label: 'Select all',
                            onItemClick: ({ context }) => {
                                const { changeSelection, visibleColumns, data } = context;

                                if (!data?.length || !visibleColumns.length) {
                                    return;
                                }

                                const tableRange = {
                                    start: { i: 0, j: 0 },
                                    end: { i: data.length - 1, j: visibleColumns.length - 1 },
                                };

                                changeSelection?.(tableRange);
                            },
                        },
                    ],
                },
                {
                    id: 'xlsxExport',
                    label: 'Save XLSX',
                    subItems: [
                        {
                            id: 'exportSelectedCells',
                            label: 'Selected cells',
                            checkVisibility: ({ context }) => {
                                const ranger = new SelectionRanger(context?.selection);
                                return ranger.checkIsSelected();
                            },
                            onItemClick: ({ context }) => {
                                const { data, visibleColumns, selection } = context;

                                if (!selection || !data?.length || !visibleColumns.length) {
                                    return;
                                }

                                const ranger = new SelectionRanger(selection);

                                const selectedColumns = visibleColumns.filter((_, colIndex) => ranger.hasCol(colIndex));
                                const selectedDataRows = data.filter((_, rowIndex) => ranger.hasRow(rowIndex));

                                xlsxExport({
                                    columns: selectedColumns,
                                    data: selectedDataRows,
                                    tableId: context.tableId,
                                    bookName: context.nameForExports,
                                    t: context.t,
                                });
                            },
                        },
                        {
                            id: 'allTable',
                            label: 'All the table',

                            onItemClick: ({ context }) => {
                                const { visibleColumns, data } = context;

                                if (!data?.length || !visibleColumns.length) {
                                    return;
                                }

                                xlsxExport({
                                    columns: visibleColumns,
                                    data,
                                    tableId: context.tableId,
                                    bookName: context.nameForExports,
                                    t: context.t,
                                });
                            },
                        },
                    ],
                },
            ],
        },
    ];

    return menuItems;
};
