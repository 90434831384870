import { ITableListTasksItem } from '../../../../types';

export const getChunkArrays = (list: ITableListTasksItem[], chunkSize: number): ITableListTasksItem[][] => {
    let array: ITableListTasksItem[][] = [];
    let array2: ITableListTasksItem[] = [];
    list.forEach((item, i) => {
        array2.push(item);
        if ((i + 1) % chunkSize === 0 && i !== 0) {
            array.push(array2);
            array2 = [];
        } else if (i === list.length - 1) {
            array.push(array2);
            array2 = [];
        }
    });

    return array;
};
