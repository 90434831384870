// React-query keys
export const GENERAL = 'GENERAL';
export const LOCALES = 'LOCALES';
export const CURRENT = 'CURRENT';
export const PROJECTS = 'PROJECTS';
export const LOCATIONS = 'LOCATIONS';
export const ALL_LOCATIONS = 'ALL_LOCATIONS';
export const CONNECTOR = 'CONNECTOR';
export const CONNECTOR_PARAMS = 'CONNECTOR_PARAMS';
export const LIST_POINTS_SALE = 'LIST_POINTS_SALE';
export const CONNECTOR_EDITOR = 'CONNECTOR_EDITOR';
export const CONNECTOR_LIST = 'CONNECTOR_LIST';
export const CONNECTOR_TYPES = 'CONNECTOR_TYPES';
export const TASKS_STORE = 'TASKS_STORE';
export const TENANTS = 'TENANTS';
export const CONNECTORS = 'CONNECTORS';
export const CONNECTOR_TASKS = 'CONNECTOR_TASKS';

export const CONNECTOR_TASKS_GET = 'CONNECTOR_TASKS_GET';
// other constants
export const KFACTORHEIGHTTABLE = 150;
export const KFACTORHEIGHTTABLE_MONITORING = 308;
