import { CONNECTOR_TASKS, CONNECTOR_TASKS_GET, TASKS_STORE, TENANTS } from '../constants';
import { useGeneralStore } from '../store';
import { useTaskStore } from '../store/useTaskStore';
import { IParamsUseGetConnectorTasks, IReqConnectorTasks, UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAddGlobalSpinner } from '../hooks';

export const useGetConnectorTasks = (obj: IParamsUseGetConnectorTasks, status: boolean) => {
    const { offset, limit, plIds, createdTo, createdFrom, dateFrom, dateTo } = obj;
    const queryKey = [TASKS_STORE, CONNECTOR_TASKS_GET];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { updateTasksStore, getTasksStore } = useTaskStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: IReqConnectorTasks) => response;

    const enabled = obj.plIds.length > 0 && Boolean(status);

    const getUrl = (_services: UrlsByServices): string => {
        return `${
            _services['fsf/metric-storage'].CONNECTOR_TASK
        }?limit=${limit}&offset=${offset}&project_location_ids=${plIds.join(',')}
        ${
            createdFrom
                ? `&created_from=${createdFrom}&created_to=${createdTo}`
                : dateFrom
                  ? `&date_from=${dateFrom}&date_to=${dateTo}`
                  : ''
        }`;
    };
    const queryFn = () =>
        getRequest({
            url: getUrl(services),
            token: token,
            onError: (error) => {
                updateGeneralStore({
                    messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4(),
                        },
                    ],
                });
            },
        });

    const { data, isFetching, error, refetch } = useQuery<IReqConnectorTasks, unknown, IReqConnectorTasks, any>({
        queryKey,
        queryFn,
        select,
        enabled,
        refetchInterval: 1000 * 5 * 60,
        staleTime: 1000 * 5 * 60,
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        updateTasksStore({
            tasks: data.results,
        });
    }, [data, updateGeneralStore]);

    useAddGlobalSpinner({ isFetching, text: 'Loading tasks' });
    return { refetch };
};
