import React, { FC, useEffect, useState } from 'react';
import { PopoverProps, Option, Checkbox, Divider, Button } from '@fluentui/react-components';
import { CustomPopover } from '../CustomPopover/CustomPopover';
import { DivItemCheckboxSC } from '../Connectors/styles';
import { Search } from '../Search/Search';
import { TOption } from '../Chapters/Tasks/interfaces';
import { SelectAllOnRegular, SelectAllOffRegular } from '@fluentui/react-icons';

import { useTranslation } from 'react-i18next';
import {
    DivBoxSearchSC,
    DivWrapperHeaderButtonSC,
    DivWrapperListItemSC,
    DivWrapperListPopoverSC,
    DivWrapperListPopoverScrollSC,
} from './styles';

export const Select: FC<{
    open: boolean;
    onOpenChange: PopoverProps['onOpenChange'];
    label: string;
    listOptions: TOption[];
    onChangeSelection: (option: TOption) => void;
    isMulti: boolean;
    selectedIds?: number[] | string[];
    selectAll?: () => void | undefined;
    clearAll?: () => void | undefined;
    isShowButtons?: boolean;
}> = (props) => {
    const {
        open,
        onOpenChange,
        label,
        listOptions,
        onChangeSelection,
        isMulti,
        selectedIds = [],
        selectAll = undefined,
        clearAll = undefined,
        isShowButtons = false,
    } = props;

    const [listSelectedOptions, setListSelectedOptions] = useState<TOption[]>([]);
    const [listOptionsTemp, setListOptionsTemp] = useState<TOption[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [namesList, setNamesList] = useState<string[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        let tempList: TOption[] = [];
        listOptions.forEach((item, i) => {
            if (selectedIds.includes(item.id as never)) {
                tempList.push(item);
            }
        });
        const namesListTemp = listOptions
            .filter((item) => selectedIds.includes(item.id as never))
            .map((item) => item.name);
        setListSelectedOptions(tempList);
        setListOptionsTemp(listOptions);
        setNamesList(namesListTemp);
    }, [listOptions, selectedIds]);

    const onSelectOption = (option: TOption) => () => {
        onChangeSelection(option);
    };

    const searchOnChange = (value: string) => {
        setSearchValue(value);
        const searchedList = listOptions.filter((it) => String(it.name).toLowerCase().includes(value.toLowerCase()));
        setListOptionsTemp(searchedList);
    };

    return (
        <CustomPopover open={open} onOpenChange={onOpenChange} label={label} labelsList={namesList} isCount={true}>
            <DivWrapperListPopoverSC>
                <DivBoxSearchSC>
                    <Search placeholder={`${t('Search')}`} handleChange={searchOnChange} />
                    {isShowButtons && (
                        <DivWrapperHeaderButtonSC>
                            <Button
                                size="small"
                                appearance="transparent"
                                disabled={listOptionsTemp.length === listSelectedOptions.length}
                                onClick={selectAll}
                                icon={<SelectAllOnRegular />}
                            >
                                {t('Select all')}
                            </Button>
                            <Button
                                size="small"
                                appearance="transparent"
                                disabled={listSelectedOptions.length === 0}
                                onClick={clearAll}
                                icon={<SelectAllOffRegular />}
                            >
                                {t('Clear all')}
                            </Button>
                        </DivWrapperHeaderButtonSC>
                    )}
                </DivBoxSearchSC>
                <DivWrapperListPopoverScrollSC>
                    <DivWrapperListItemSC>
                        {listOptionsTemp.map((option, i) => {
                            if (isMulti) {
                                return (
                                    <DivItemCheckboxSC key={`dropdown-tasks-${i}`}>
                                        <Checkbox
                                            checked={selectedIds.includes(option.id as never)}
                                            onChange={onSelectOption(option)}
                                            label={option.name}
                                        />
                                    </DivItemCheckboxSC>
                                );
                            }

                            return (
                                <Option
                                    onClick={onSelectOption(option)}
                                    value={option.name}
                                    key={`dropdown-tasks-${i}`}
                                >
                                    {option.name}
                                </Option>
                            );
                        })}
                    </DivWrapperListItemSC>
                    {listSelectedOptions.length > 0 && <Divider vertical style={{ height: '100%' }} />}
                    {listSelectedOptions.length > 0 ? (
                        <DivWrapperListItemSC>
                            {listSelectedOptions.map((option, i) => {
                                return (
                                    <DivItemCheckboxSC key={`dropdown-tasks-${i}`}>
                                        <Checkbox
                                            checked={selectedIds.includes(option.id as never)}
                                            onChange={onSelectOption(option)}
                                            label={option.name}
                                        />
                                    </DivItemCheckboxSC>
                                );
                            })}
                        </DivWrapperListItemSC>
                    ) : null}
                </DivWrapperListPopoverScrollSC>
            </DivWrapperListPopoverSC>
        </CustomPopover>
    );
};
