const enumStatuses: { [key: string]: string } = {
    '0': 'No receipts',
    '-1': 'No sale',
    '-2': 'Maintenance',
    '-3': 'Saved to bucket',
    '-4': 'Pending',
    '-5': 'Db err',
    '-6': 'Fsf err',
    '-7': 'Cred err',
    '-8': 'Ofd err',
    '-9': 'No task',
    '-10': 'Hold',
    '-11': 'Cancelled',
};

const getStatus = (num: number | null): string => {
    return enumStatuses[String(num)] ? enumStatuses[String(num)] : 'Success';
};

export default getStatus;
