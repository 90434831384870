import { DivItemContentTooltipSC, WrapperTooltipContentSC } from '../../styles';
import { FC } from 'react';
import { IPropsTooltipContent } from '../../interfaces';

export const TooltipContent:FC<IPropsTooltipContent> = (props) => {
    const {
        textList
    } = props

    return (
        <WrapperTooltipContentSC>
            {
                textList.map((item, i) => {
                    return (
                        <DivItemContentTooltipSC key={`dsfxzcvdf-${i}`}>
                            {item}
                        </DivItemContentTooltipSC>
                    )
                })
            }
        </WrapperTooltipContentSC>
    );
};