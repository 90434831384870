import { Field, Label } from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import React from 'react';

export const DatePickerFrom = (props: {
    htmlFor: string;
    label: string;
    styles: Record<string | number, string>;
    onSelectDate: (date: Date | null | undefined) => void;
    formatDate: (date?: Date | undefined) => string | 'Invalid DateTime';
    selectedDateFrom: string | null;
    maxDateForDateFromCalendar: Date;
}) => {
    const { htmlFor, label, styles, onSelectDate, formatDate, selectedDateFrom, maxDateForDateFromCalendar } = props;
    return (
        <div>
            <Label htmlFor={htmlFor}>{label}</Label>
            <Field id={htmlFor}>
                <DatePicker
                    className={styles.control}
                    placeholder={'label'}
                    onSelectDate={onSelectDate}
                    formatDate={formatDate}
                    value={selectedDateFrom ? new Date(selectedDateFrom) : null}
                    maxDate={maxDateForDateFromCalendar}
                />
            </Field>
        </div>
    );
};