import { IConnectorItemListForTable } from '../components/Chapters/Tasks/interfaces';
import { TTableListTasks } from '../types';

interface Args<T> {
    keys: string[];
    list: T[];
    valueFilter: string;
    typeCompare?: 'strict' | 'low';
}

const filteringTableList = <T>(params: Args<T>): T[] => {
    const { keys, list, valueFilter, typeCompare = 'low' } = params;

    if (typeCompare === 'strict') {
        if (keys.length === 2) {
            return list.filter(
                (item) =>
                    String(item[keys[0] as keyof T]) === String(valueFilter) ||
                    String(item[keys[1] as keyof T]) === String(valueFilter),
            );
        } else if (keys.length === 1) {
            return list.filter((item) => {
                return String(item[keys[0] as keyof T]) === String(valueFilter);
            });
        }
    } else {
        if (keys.length === 2) {
            return list.filter(
                (item) =>
                    String(item[keys[0] as keyof T])
                        .toLowerCase()
                        .includes(valueFilter.toLowerCase()) ||
                    String(item[keys[1] as keyof T])
                        .toLowerCase()
                        .includes(valueFilter.toLowerCase()),
            );
        } else if (keys.length === 1) {
            return list.filter((item) => {
                return String(item[keys[0] as keyof T])
                    .toLowerCase()
                    .includes(valueFilter.toLowerCase());
            });
        }
    }

    return list;
};

export default filteringTableList;
