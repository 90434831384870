import { ISection, ISidebarConfig, ISubsection, TMenu } from '../interfaces';
import { sidebarConfig } from '../sidebarConfig';

interface IArgs {
    /** Конфигурация модулей */
    sidebarConfig: ISidebarConfig[];
}

/**
 * Функция генерации данных для бокового меню
 */
const generateMenu = (args: IArgs): TMenu => {
    const { sidebarConfig } = args;

    const combinedModulesConfig = [...sidebarConfig];

    const result = combinedModulesConfig
        // .filter((item) => {
        //     return item.permissions.some((perm) => permissions.includes(perm));
        // })
        .reduce((acc: { [chapterName: string]: ISection | ISubsection }, value) => {
            const chapterName = value.module_name.split(':')[0];
            const moduleName = value.module_name.split(':')[1];
            const module_id = value.module_id;

            const subsection: ISubsection = {
                type: 'module',
                module_id,
                name: moduleName,
                servicePath: value.service.path,
                chapterName: chapterName,
                disabled: value.permissions.length !== 0,
            };

            if (acc[chapterName]) {
                (acc[chapterName] as ISection).subsections.push(subsection);
            } else {
                acc[chapterName] = {
                    type: 'chapter',
                    name: chapterName,
                    subsections: [subsection],
                };
            }

            return acc;
        }, {});

    return Object.values(result);
};

export default generateMenu;
