const getStatusTaskSource = (num: number | null): string => {
    if (num === 0) {
        return 'Monthly';
    } else if (num === null) {
        return 'Daily';
    }
    return 'User';
};

export default getStatusTaskSource;
