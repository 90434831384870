import { PopoverTrigger, PopoverProps, Popover } from '@fluentui/react-components';
import { FC, useRef } from 'react';


import { StyledPopoverSurface } from './styles';
import { IProps } from './interfaces';
import { useClickOutside } from '../../hooks/useClickOutside';

/**
 * Компонент для отображения кастомного контекстного меню
 */
const ContextualMenu: FC<IProps> = ({
    styles = { padding: '0' },
    positioning = 'start',
    isContextualMenuOpen,
    contextualMenuBody,
    closeContextualMenu,
    gapSpace = 5,
    children,
    positioningRef,
    closeOnClickOutside,
}) => {
    const refPopoverMenu = useRef(null);

    const handleOpenChange: PopoverProps['onOpenChange'] = (_, data) => {
        // if (document.activeElement instanceof HTMLElement) {
        //     document.activeElement.blur();
        // }
        !data.open && closeContextualMenu();
    };

    useClickOutside([refPopoverMenu], (event: MouseEvent) => {
        if (closeOnClickOutside && isContextualMenuOpen) {
            closeContextualMenu(event);
        }
    });

    return (
        <Popover
            positioning={{ offset: gapSpace, position: 'below', align: positioning, positioningRef }}
            onOpenChange={handleOpenChange}
            open={isContextualMenuOpen}
            closeOnScroll={true}
        >
            <PopoverTrigger>{children}</PopoverTrigger>
            <StyledPopoverSurface ref={refPopoverMenu} style={styles}>
                {contextualMenuBody}
            </StyledPopoverSurface>
        </Popover>
    );
};

export default ContextualMenu;
