import { TListSettings } from '../../interfaces';

const initialListTaskStatusesFilter: TListSettings = [
    {
        name: '>0 success',
        id: '>0Success',
        checked: true,
        status: '>0',
        message: 'success',
    },
    {
        name: '0 no receipts',
        id: '0NoReceipts',
        checked: true,
        status: 0,
        message: 'no receipts',
    },
    {
        name: '-1 no sale',
        id: '-1NoSale',
        checked: true,
        status: -1,
        message: 'no sale',
    },
    {
        name: '-2 maintenance',
        id: '-2Maintenance',
        checked: true,
        status: -2,
        message: 'maintenance',
    },
    {
        name: '-3 save to bucket',
        id: '-3SaveToBucket',
        checked: true,
        status: -3,
        message: 'save to bucket',
    },
    {
        name: '-4 pending',
        id: '-4Pending',
        checked: true,
        status: -4,
        message: 'pending',
    },
    {
        name: '-5 db err',
        id: '-5DbErr',
        checked: true,
        status: -5,
        message: 'db err',
    },
    {
        name: '-6 fsf err',
        id: '-6FsfErr',
        checked: true,
        status: -6,
        message: 'fsf err',
    },
    {
        name: '-7 cred err',
        id: '-7CredErr',
        checked: true,
        status: -7,
        message: 'cred err',
    },
    {
        name: '-8 ofd err',
        id: '-8OfdErr',
        checked: true,
        status: -8,
        message: 'ofd err',
    },
    {
        name: '-9 no task',
        id: '-9NoTask',
        checked: true,
        status: -9,
        message: 'no task',
    },
    {
        name: '-10 hold',
        id: '-10Hold',
        checked: true,
        status: -10,
        message: 'hold',
    },
    {
        name: '-11 cancel',
        id: '-11Cancel',
        checked: true,
        status: -11,
        message: 'cancel',
    },
];

export default initialListTaskStatusesFilter;
