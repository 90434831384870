import { useEffect, useState } from 'react';
import { IPropsUseCreateTableListTasks, ITableListTasksItem, TTableListTasks } from '../types';
import { useTaskStore } from '../store/useTaskStore';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants/dateFormat';
import { getFormatDate } from '../tools/getFormatDate';
import { roundMillisecond } from '../components/Chapters/Tasks/tools/roundMillisecond';
import getColor from '../components/Chapters/Tasks/tools/getColor';
import getStatus from '../components/Chapters/Tasks/tools/getStatuses';
import getStatusTaskSource from '../components/Chapters/Tasks/tools/getStatusTaskSource';

export const useCreateTableListTasks = (params: IPropsUseCreateTableListTasks) => {
    const { tenants, connectors, tasks, PL } = params;
    const [tableList, setTableList] = useState<TTableListTasks>([]);

    const { updateTasksStore } = useTaskStore();

    useEffect(() => {
        if (tasks.length > 0 && tenants.length > 0 && connectors.length > 0 && PL.length > 0) {
            let tempList: TTableListTasks = [];

            let objTasks: { [key: number]: ITableListTasksItem } = {};

            tasks.forEach((item, i) => {
                const foundConnector = connectors.find((item2) => item2.id === item.connector_id);
                let foundTenant;
                if (foundConnector) {
                    foundTenant = tenants.find((item2) => item2.id === foundConnector.tenant_id);
                }
                let foundPL;

                if (foundTenant) {
                    foundPL = PL.find((item2) => {
                        return item2.id === foundTenant.project_location;
                    });
                }

                let tempObj: ITableListTasksItem = {
                    id: item.id,
                    connectorType: item.connector_type ? item.connector_type : '',

                    isIsolatedWorkers: item.isolated_workers,
                    status: getStatus(item.status_code !== null ? item.status_code : -9),
                    error: item.error ? item.error : '',
                    createdAt: getFormatDate(item.created_at, DATE_TIME_FORMAT),
                    tenantName: foundTenant?.name,
                    PLName: foundPL?.name,
                    PLId: foundPL?.id,
                    useId: item.user_id,
                    taskSource: getStatusTaskSource(item.user_id),
                    priority: item.priority,
                    attempt: item.attempt,
                    startedAt: getFormatDate(item.started_at, DATE_TIME_FORMAT),
                    delayedTo: getFormatDate(item.delayed_to, DATE_TIME_FORMAT),
                    duration: item.duration ? roundMillisecond(item.duration) : '',
                    tenantId: foundTenant?.id,
                    dateFrom: getFormatDate(item.date_from, DATE_FORMAT),
                    dateTo: getFormatDate(item.date_to, DATE_FORMAT),
                    statusCode: item.status_code !== null ? item.status_code : -9,
                    billsNumber: item.status_code ? (item.status_code > 0 ? item.status_code : '') : '',
                    rowBGColor:
                        item.status_code !== undefined && item.status_code !== null
                            ? getColor(item.status_code)
                            : getColor(-9),
                    connectorId: item.connector_id,
                };

                objTasks[item.id] = tempObj;

                tempList.push({
                    ...tempObj,
                });
            });
            updateTasksStore({
                tableListTasks: tempList,
                tableListTasksCTG: tempList,
                taskDataByTaskId: objTasks,
            });
        }
    }, [tenants, connectors, tasks, PL]);
};
