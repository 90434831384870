import styled from 'styled-components';

export const DivWrapperListPopoverSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: 50px auto;
    gap: 10px;
`

export const DivBoxSearchSC = styled.div`
    display: grid;
    grid-template-rows: 32px max-content;
    grid-template-columns: 100%;
    gap: 5px;
`

export const DivWrapperListPopoverScrollSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: 100%;
`

export const DivWrapperListItemSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    max-height: 300px;
    overflow: auto;
    grid-auto-rows: 32px;
`
export const DivItemCheckboxSC = styled.div`
    display: grid;
    height: max-content;
    width: max-content;
`
export const DivWrapperHeaderButtonSC = styled.div`
    width: 100%;
    height: 18px;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
`