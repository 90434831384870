import { DatePicker } from 'antd';
import { WrapperDateRangeSC } from './styles';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';
import { IPropsDateRangePicker } from './interfaces';
import { getFormatDate } from './tools/getFormatDate';
import { Label } from '@fluentui/react-components';
const { RangePicker } = DatePicker;

/**
 * @name DateRangePicker
 * @param {IPropsDateRangePicker} props
 * @return ReactComponent
 * */

export const DateRangePicker: FC<IPropsDateRangePicker> = (props) => {
    const { valueFrom, valueTo, onChange, formatDate, title, maxDate = undefined } = props;

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            onChange(getFormatDate(dates[0], formatDate), getFormatDate(dates[1], formatDate));
        } else {
            onChange(null, null);
        }
    };

    return (
        <WrapperDateRangeSC>
            <Label>{title}</Label>
            <RangePicker
                maxDate={maxDate}
                onChange={onRangeChange}
                value={[valueFrom ? dayjs(valueFrom) : null, valueTo ? dayjs(valueTo) : null]}
                allowEmpty={[true, true]}
            />
        </WrapperDateRangeSC>
    );
};
