import React from 'react';
import { useAuth, useInitialRequests } from 'src/hooks';
import './App.css';
import { GlobalSpinner } from './components/GlobalSpinner/GlobalSpinner';
import { Header } from './components/Header/Header';
import { AppRouter } from './AppRouter';
import { TokenExpiration } from './components/TokenExpiration/TokenExpiration';
import { Alert } from './components/Alert/Alert';
import { SectionSC } from './globalStyles';
import { Sidebar } from './components/Sidebar/Sidebar';
import { useInitialFills } from './hooks/ useInitialFills';

function App() {
    useInitialRequests();
    useAuth();
    useInitialFills();

    return (
        <div className="App">
            <Header />
            <GlobalSpinner />
            <SectionSC>
                <Sidebar />
                <AppRouter />
            </SectionSC>

            <TokenExpiration />
            <Alert />
        </div>
    );
}

export default App;
