import { Interval, DateTime } from 'luxon';
import processNullToDate from './processNullToDate';
import { IParamsGetIsIntersection } from '../types';

const getIsIntersection = (params: IParamsGetIsIntersection) => {
    let status = false
    if (!params.dateFromCompare && !params.dateToCompare) {
        status = true;
    }
    else {
        const mainInterval = Interval.fromISO(`${processNullToDate(params.dateFromMain, 'dateFrom')}/${processNullToDate(params.dateToMain, 'dateTo')}`);
        const compareInterval = Interval.fromISO(`${processNullToDate(params.dateFromCompare, 'dateFrom')}/${processNullToDate(params.dateToCompare, 'dateTo')}`);


        if (DateTime.fromISO(processNullToDate(params.dateFromCompare, 'dateFrom')).toMillis()
            >
            DateTime.fromISO(processNullToDate(params.dateToCompare, 'dateTo')).toMillis()) {
            status = false
        }
        else {
            if (compareInterval.intersection(mainInterval)) {
                status = true;
            }
        }


    }

    return status
}

export default getIsIntersection

