import * as Highcharts from 'highcharts';
import React, { FC, useEffect, useRef, useState } from 'react';
import { DivWrapperChartSC } from '../../styles';
import { IPropsChartBox } from '../../interfaces';
import moreHicharts from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { Solidguage } from '../../../../Charts/Solidgauge/Solidguage';
import { options } from '../settings/optionsChart';
import { colorsByNameStatus } from '../settings/colorsByNameStatus';
import { useTranslation } from 'react-i18next';

moreHicharts(Highcharts);
solidGauge(Highcharts);

const listStatuses: string[] = ['success', 'noReceipts', 'pending', 'error'];

export const ChartBox: FC<IPropsChartBox> = (props) => {
    const { statuses } = props;
    const { t } = useTranslation();
    const [listOptions, setListOptions] = useState<Highcharts.Options[]>([]);

    useEffect(() => {
        const list: Highcharts.Options[] = listStatuses.map((item) => {
            return {
                ...options,
                series: [
                    {
                        name: t(statuses[item].name),
                        // @ts-ignore
                        // colorByPoint: true,
                        // innerSize: '75%',
                        type: 'solidgauge',
                        data: [
                            {
                                color: colorsByNameStatus[item],
                                radius: '90%',
                                innerRadius: '66%',
                                y: statuses[item].number,
                            },
                        ],
                    },
                ],
                title: {
                    text: `<span>${t(statuses[item].name)}</span>`,
                    style: {
                        fontSize: '16px',
                    },
                    verticalAlign: 'middle',
                },
            };
        });
        // @ts-ignore
        setListOptions(list);
    }, [statuses]);

    return (
        <DivWrapperChartSC>
            {listOptions.map((item, i) => {
                return <Solidguage statuses={statuses[listStatuses[i]]} key={`charts-${i}`} options={item} />;
            })}
        </DivWrapperChartSC>
    );
};
