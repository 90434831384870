// @ts-ignore
import * as Highcharts from 'highcharts';
import moreHicharts from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
moreHicharts(Highcharts);
solidGauge(Highcharts);
// @ts-ignore
const trackColors = Highcharts.getOptions().colors.map((color) => new Highcharts.Color(color).setOpacity(0.3).get());

// @ts-ignore
// @ts-ignore
export const options: Highcharts.Options = {
    chart: {
        type: 'solidgauge',
        height: '200px',
        // width: '200px',
        // events: {
        //     render: renderIcons,
        // },
    },

    title: {
        text: 'Multiple KPI gauge',
        style: {
            fontSize: '14px',
        },
    },

    // tooltip: {
    //     borderWidth: 0,
    //     backgroundColor: 'none',
    //     shadow: false,
    //     style: {
    //         fontSize: '16px',
    //         color: 'grey',
    //     },
    //     valueSuffix: '%',
    //     pointFormat:
    //         '<span style="font-size: 1em; color: {point.color}; ' +
    //         'font-weight: bold; padding-left: 10px;">{point.y}</span>',
    //     positioner: function (labelWidth) {
    //         return {
    //             x: (this.chart.chartWidth - labelWidth) / 2,
    //             y: this.chart.plotHeight / 2 + 35,
    //         };
    //     },
    // },

    pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
            {
                // Track for Conversion
                outerRadius: '90%',
                innerRadius: '66%',
                backgroundColor: 'hsl(210, 20%, 95%)',
                borderWidth: 0,
            },
        ],
    },

    yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
    },

    plotOptions: {
        solidgauge: {
            dataLabels: {
                enabled: false,
            },
            linecap: 'round',
            stickyTracking: true,
            rounded: true,
        },
    },

    series: [
        {
            name: 'Error',
            data: [
                {
                    // @ts-ignore
                    color: Highcharts.getOptions().colors[0],
                    radius: '90%',
                    innerRadius: '66%',
                    y: 80,
                },
            ],
            custom: {
                icon: 'filter',
                iconColor: '#303030',
            },
        } as any,
    ],
};
