import { ISidebarConfig } from './interfaces';

export const sidebarConfig: ISidebarConfig[] = [
    {
        module_name: 'Tasks:Create',
        module_id: 'Tasks:Create',
        hasOptionsPanel: true,
        service: {
            path: '/tasks/create',
        },
        permissions: [],
    },
    {
        module_name: 'Tasks:Monitoring',
        module_id: 'Tasks:Monitoring',
        hasOptionsPanel: true,
        service: {
            path: '/tasks/monitoring',
        },
        permissions: [],
    },
    {
        module_name: 'Configuration:Connectors',
        module_id: 'Configuration:Connectors',
        hasOptionsPanel: true,
        service: {
            path: '/configuration/connectors',
        },
        permissions: [],
    },
    {
        module_name: 'Service:Monitoring',
        module_id: 'Service:Monitoring',
        hasOptionsPanel: false,

        service: {
            path: '/service/monitoring',
        },
        permissions: [],
    },
];
