import { ColumnOptions } from '../../../../FocusTable';

export const columnsOptions: ColumnOptions[] = [
    { id: 'id', label: 'POS ID', visible: false },
    { id: 'tenantId', label: 'Tenant id' },
    { id: 'tenantName', label: 'Tenant name',},
    { id: 'locationId', label: 'Location id' },
    // { id: 'posName', minWidth: 200, title: 'Pos Details' },
    { id: 'locationName', label: 'Location name'},
    { id: 'connectorType', label: 'Connector type' },
    { id: 'connectorId', label: 'Connector id' },
    { id: 'dateForm', label: 'Date from' },
    { id: 'dateTo', label: 'Date to' },
    // { id: 'accumulatorId', style: { minWidth: 200 }, label: 'Fiscal device POS ID', align: 'right', visible: false },
    // { id: 'dateFrom', style: { minWidth: 120 }, sortOrder: 'asc' },
    // { id: 'dateTo', style: { minWidth: 120 }, sortOrder: 'asc' },
    // { id: 'firstDate', label: 'Date of first receipt', hasSort: false, hasFilter: false },
    // { id: 'firstTime', label: 'Time of first receipt', hasSort: false, hasFilter: false, visible: false },
    // { id: 'lastDate', label: 'Date of last receipt', hasSort: false, hasFilter: false },
    // { id: 'lastTime', label: 'Time of last receipt', hasSort: false, hasFilter: false, visible: false },
    // { id: 'sales', label: 'Sales (Inc. VAT)', hasSort: false, hasFilter: false },
    // { id: 'receiptsCount', label: 'Num of Receipts', hasSort: false, hasFilter: false },
];
